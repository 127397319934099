import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button, Checkbox, Row, Col, notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { signInUser } from '../authTokenConfig';
import { RcBaseFormProps } from 'antd/lib/form/Form';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { API } from '../api/api';
const FormItem = Form.Item;

const { Header, Content, Footer } = Layout;

interface IForgotProps {
  signInUser?: (dispacth: any) => Promise<any>;
  form: any;
  history: any;
};

interface IForgotState {
  email: String;
  password: String;
}

class ForgotPassword extends React.Component<IForgotProps, IForgotState> {

  submitForm(e: any) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let data ={"email": values.email, "redirect_url":"https://"+window.location.hostname + "/resetPassword"}
        API.ForgotPassword(data).then(r=>{
          notification.success({
            message: 'Check your e-mail inbox!',
            description: 'We have sent instructions on how to reset your password.',
            duration: 10,
            className:'auth-msg'
          });
        }).catch(e=>{
            notification.error({
                message: 'Error',
                description: e.response.data.errors[0],
                duration: 10,
                className:'auth-msg'
              });
        })
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className='forgot-password auth-screen'>
        <Row>
          <Col md={10} className='content'>
            <div className='logo'>
                <img src='/assets/images/logo.png'/>
                <h1> Build Bots with ease </h1>
            </div>
        
            <Form onSubmit={e => this.submitForm(e)} className='form' layout='vertical'>
              <h3>Enter your email and we will send you a password reset link.</h3>
              <FormItem >
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: 'Please input your email!' }],
                })(
                  <Input placeholder='Email' type='email'/>
                )}
              </FormItem>
              <FormItem>
              <Button htmlType='submit' className='submit'>
                Send Request
              </Button>
              <div className='link'>
                <Link to='/signin'>
                    <span>Return to sign in</span>
                </Link>
              </div>        
              </FormItem>
            </Form>
            </Col>
          <Col md={14}>
            <div className=''>
              <img width='100%' src='/assets/images/homeBg.png'/>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(ForgotPassword);

export default connect<RcBaseFormProps & Pick<IForgotProps, 'signInUser'>>(
  null,
  { signInUser },
)(withRouter(WrappedNormalLoginForm));
