import * as React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { Spin, Icon } from 'antd';
import App from './App';
interface IRootProps {
  isAttempted: Boolean;
};

interface IRootState { };

class Root extends React.Component<IRootProps> {

  render() {
      const antIcon = <Icon type='loading' style={{ fontSize: 24 }} spin />;
      return(
        !this.props.isAttempted ? <Spin indicator={antIcon} size='large' /> : <App />
      );
  }
}

function mapStateToProps(state: any) {
  return {
      isAttempted: state.reduxTokenAuth.currentUser.hasVerificationBeenAttempted
  };
}

export default connect(mapStateToProps)(Root);
