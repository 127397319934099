import * as React from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown, Icon, Button,List, Row, Col, Divider,Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { API } from '../../api/api';
import { setCurrentProject,createProject } from '../../actions';
import { setProjects,deleteProject,setProjectId } from '../../actions';
import './project.css'

interface IHomeProps {
  user?: any;
  currentProject?: number;
  setCurrentProject: (dispatch: any) => void;
  projects?:any;
  setProjects: (projects: any) => void;
  deleteProject: (projectId: any) => void;
  setProjectId: (projectId: any) => void;
  createProject: (projectId: any) => void;

};

interface IHomeState {
};

class Account extends React.Component<IHomeProps, IHomeState> {

  componentDidMount() {
    this.props.setCurrentProject(undefined);
    console.log(this.props.currentProject)
    if (this.props.user && this.props.user.attributes)
      API.getProjects(this.props.user.attributes.id).then( r => {
        this.props.setProjects(r.data.user_projects)
      })

  }


  render() {
    return (
      <div className='projects'>
        <h1 className='title'> My Projects </h1>
        <Row>
          <Col md={12} lg={8}>
            <div className='add-card'>
            <Link style={{ display:'flex', flexDirection:'column' }} to="/newProject">
              <img src='/assets/images/Add.svg'/>
              <h1 style={{color: '#fff'}}> Create project </h1>
            </Link>           
            </div>
          </Col>
          {this.props.projects.map(project=>{
            const status = project.is_private? 'Private':'Public'
            return <Col key={project.id} md={12} lg={8}>
              <div className='project-card'>
                {/* <small>date</small> */}
                <Link onClick={() => this.props.setCurrentProject(project.id)} 
                      to={'/project/'+project.id+'/accountHome'}> 
                      <h1>{project.name}</h1>
                </Link>
  
                <button className={status + ' btn'}>{status}</button>
                <div className='icons'>
                  <span >
                    <a style={{width:20}} target='_blank' href={`https://chat.optobot.ai/chat/${project.id}`}><img src='/assets/images/chat.svg'/></a>
                  </span>
                  <Popconfirm
                    title="Are you sure you want to delete this project?"
                    onConfirm={()=> this.props.deleteProject(project.id) }
                    // onCancel={()=>}
                    okText="Yes"
                    cancelText="No"               
                  >
                    <img  style={{float:'right'}} src='/assets/images/delete.svg'/>
                  </Popconfirm>
                 
                </div>
              </div>
            </Col>
          })}
        </Row>
        <div className='github'>
          <h1>Or</h1>
          <p>{'Download open-source Optobot and get started!'}</p>
            <a target='_blank' href='https://github.com/Optomatica/optobot'>
              <div className='project-card'>
                <img width='100px' src='/assets/images/github.png'/>
              </div>

            </a>
        </div>
        
      </div>
    );
  }
}


function mapStateToProps(state: any) {
  return {
    user: state.reduxTokenAuth.currentUser,
    projects: state.projects,
    currentProject: state.bot.currentProject,
    projectId: state.projectId,

  };
}

const mapDispatchToProps = {
  setCurrentProject,
  setProjects,
  deleteProject,
  createProject,
  setProjectId
};

export default connect<any>(
  mapStateToProps,
  mapDispatchToProps
)(Account)
