import * as React from 'react';
import { connect } from 'react-redux';
import { Row, Card, List, Button, Form, Modal, Input, Radio, Divider } from 'antd';
import {API} from '../../../api/api';
import { withRouter } from 'react-router-dom';
import { changeContext, addDialogue, setMode } from '../../../actions';
import { Mode } from '../../../constants/types';
import * as _ from 'lodash';
const { Meta } = Card;
const FormItem = Form.Item;

interface IContextsProps {
  changeContext?: ( contextId: number ) => Promise<any>;
  addDialogue?: () => void;
  selectedContext?: any;
  mode: Mode;
  setMode?: (modE: Mode) => void;
  currentProject?: number;
  match?:any;
};

interface IContextsState {
  contexts: any[];
  addingContext: boolean;
};

const CollectionCreateForm = Form.create()(
  class extends React.Component<any> {
    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title='Create a new context'
          okText='Create'
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout='vertical'>
            <FormItem label='Name'>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Please input the name of the context!' }],
              })(
                <Input />
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

class Contexts extends React.Component<IContextsProps, IContextsState> {
  state = {
    contexts: [],
    addingContext: false
  };

  contextFormRef = null;

  componentDidMount() {
    API.listContexts(this.props.match.params.id).then(r => {
      this.setState({contexts: r});
      //edited
      //this.props.changeContext(null);
    });
  }

  deleteContext = () => {
    API.deleteContext(this.props.match.params.id,this.props.selectedContext).then(r => {
      let contexts=this.state.contexts;
      let ind = contexts.findIndex((e:any) => e.id ===  this.props.selectedContext);
      contexts.splice(ind, 1);
      this.setState({contexts});
    });
  }

  showModal = () => {
    this.setState({ addingContext: true });
  }
  handleCancel = () => {
    this.setState({ addingContext: false });
  }
  addContext = () => {
    const context:any = this.contextFormRef
    const form = context && context.props && context.props.form;
    form && form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log('Received values of form: ', values,this.props.match.params.id);
      form.resetFields();
      this.setState({ addingContext: false });

      API.addContext(this.props.match.params.id,values).then(r => {
        let contexts:any = _.clone(this.state.contexts);
        contexts.push(r.data);
        // let ind=contexts.findIndex(e => e.name ===  "Context-Free");
        // contexts.splice(ind, 1);
        this.setState({contexts});
      });
    });
  }

  saveFormRef = (formRef) => {
    this.contextFormRef = formRef;
  }

  render() {
    return (
      <Card className='filters panel panel-info' title='Contexts'
      // justify='center'
      extra={<Radio.Group value={Mode[this.props.mode.toString()]} onChange={e => {
        let x: string = e.target.value.toString();
        this.props.setMode!(Mode[x]); }
      } style={{position: 'absolute', right: 10, top: 12}}>
        <Radio.Button value='edit'>Edit</Radio.Button>
        <Radio.Button value='chat'>Try out</Radio.Button>
      </Radio.Group>
    }
    actions={[<Row type='flex' justify='end' className='card-footer'>
    {this.props.selectedContext != null && <Button onClick={() => this.deleteContext()} type='danger'>Delete</Button>}
    <Button onClick={() => this.props.addDialogue!()}>Add Dialogue</Button>
    <Button onClick={() => this.setState({addingContext: true})} type='primary'>Add Context</Button>
    </Row>]}>
      {/* <Meta title='Contexts' /> */}
      <List
        itemLayout='horizontal'
        dataSource={this.state.contexts}
        renderItem={context => (
          <List.Item style={{backgroundColor: this.props.selectedContext === context.id ? 'dodgerblue' : 'transparent', borderRadius: 4}}>
          <a style={{color: this.props.selectedContext === context.id ? 'white' : '#1890ff', marginLeft: 10}}
            onClick={() => this.props.changeContext!(context.id)}>{context.name}</a></List.Item>
        )}
      />
      <CollectionCreateForm
        wrappedComponentRef={this.saveFormRef}
        visible={this.state.addingContext}
        onCancel={this.handleCancel}
        onCreate={this.addContext}
      />
      </Card>
    );
  }
}

function mapStateToProps(state: any) {
  let {selectedContext, mode, currentProject} = state.bot;
  return {
    selectedContext, mode, currentProject
  };
}

const mapDispatchToProps = {
  changeContext,
  setMode,
  addDialogue
};

export default connect<IContextsProps>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Contexts));
;
