import * as types from '../constants/ActionTypes';
import {assign} from '../assign';

const initialState = [];

export default function projectsReducer(state: any = initialState, action: any) {
  switch (action.type){
    case types.SET_PROJECTS:
      return [ ...action.projects];
    default:
      return state;
  }
}
