import {CHANGE_LANGUAGE} from '../constants/ActionTypes';

const languageList = [{ name: 'English', value: 'en' }, {name: 'Swedish', value: 'sv'}];

const initialState = languageList[0];

export default function changeLang(state: any = initialState, action: any) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return languageList.filter( l => l.value === action.language )[0];
    default:
      return state;
  }
}
