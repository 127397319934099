import * as React from 'react'
import {connect} from 'react-redux'
import {Form,Input,AutoComplete, Button } from 'antd'
import {API} from '../api/api'
import {sendMsgToWit,getEntities,getEntityValues,postEntity,createWitSample,getAllSamples,deleteSample} from '../actions/index'
import SelectionHighlighter from 'react-highlight-selection';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as _ from 'lodash';
const FormItem = Form.Item;


interface witProps{
    form:any,
    match?:any,
    user?:any,
    language?:any,
    wit?:any,
    sendMsgToWit?:(message?:any,access_token?:any)=>void;
    getEntities?:(access_token?:any)=>void,
    postEntity?:(new_entity?:any,access_token?:any)=>void,
    getEntityValues?:(entity?:any,access_token?:any)=>void
    createWitSample?:(message?:any,values?:any,access_token?:any)=>void
    getAllSamples?:(access_token?:any)=>void
    deleteSample?:(text?:any,access_token?:any)=>void
}


class WitAi extends React.Component<witProps>{
    state={
        access_token:'',
        message:'',
        showEntities:false,
        dataSource:this.props.wit.entities,
        new_entity:'',
        entity_values:[],
        msgEntities:[] as any,
        propsEntities:this.props.wit.msg.entities,
        new_entity_value:'',
        submitValues:[] as any,
        showInput:false,
        showText:false,
        showLink:false,
        disabled:true,
        highlightText:'Please highlight the relevant part of the sentence',
        style:{display:'block'}
    }

    componentDidMount(){
        if (this.props.match.params.id) {
              API.getProject(this.props.user.attributes.id,this.props.match.params.id).then(r => {
              this.props.getEntities!(r.data.nlp_engine[this.props.language.value])
              this.setState({access_token:r.data.nlp_engine[this.props.language.value]});
            });
        }
    }

    //inputChange 
    //get available Entities from message
    handleInputChange=(e)=>{
        this.setState({message:e.target.value});
        if(this.state.message.length>0){
            this.setState({disabled:false})
        }else{
            this.setState({disabled:true,showInput:false,showText:false})
        }
        setTimeout(()=>{
            this.props.sendMsgToWit!(this.state.message,this.state.access_token);
            if(!_.isEmpty(this.props.wit.msg.entities)){
                this.setState({showEntities:true})
            }else{
                this.setState({showEntities:false})
            }
        },3000)
    }
    
    //onBlur & onFocus Input
    handleBlur=()=>{console.log("Blur")}
    handleFocus=()=>{
         this.setState({disabled:true,
               showInput:false,
               showText:false,
            //    showEntities:false
            })
        }


    //selectChange
    handleSelectChange=(inputValue)=>{
        this.setState({new_entity:inputValue,highlightText:'Please highlight the relevant part of the sentence'});
        this.props.getEntityValues!(inputValue,this.state.access_token);
        setTimeout(()=>{
            if(this.props.wit.entity_values.length>0 || inputValue=='intent'){
                let values = [] as any;
                this.props.wit.entity_values.map(v=>values.push(v.value));
                this.setState({showText:false,showInput:true,entity_values:values});
            }else if(inputValue==''){
                this.setState({showInput:false,showText:false,entity_values:[]})
        
            }else {
                this.setState({showInput:false,showText:true})
        }},1000);
        let string = this.state.new_entity.replace(/\s+/g,' ').trim();
        if(!this.state.dataSource.includes(string) && string != ''){
            this.setState({showLink:true})
        }else{this.setState({showLink:false})}
    }

    //selection text handler
    //select entity values
    selectionHandler=(selection)=>{
        this.setState({highlightText:selection.selection})
        if(selection && this.state.new_entity){       
          const arr = {key:this.state.new_entity,value:selection.selection,start:selection.selectionStart,end:selection.selectionEnd};
          this.setState({msgEntities : this.state.msgEntities.concat([arr])})
          this.setState({showInput:false,showText:false,new_entity:'',showEntities:true})
          console.log(this.state.msgEntities)
        }
        else{this.setState({showText:false})}
    }
    
    //inputEntityValues
    //selectEntityValues 
    handleSelectValueChange = debounce(
        (inputValue) => {
            this.setState({new_entity_value:inputValue})
            if(this.state.new_entity_value && this.state.new_entity){
                const arr = {key:this.state.new_entity,value:this.state.new_entity_value};
                this.setState({msgEntities : this.state.msgEntities.concat([arr])})
                this.setState({showInput:true,showText:false,showLink:false,new_entity:'',showEntities:true})
                console.log(this.state.msgEntities)
              }else{this.setState({showInput:false})}
        },  
        1000);
        
    // create new entity
    createNewEntity=()=>{
        console.log(this.state.new_entity);
        // debugger
        // noExtraSpaces
        let string = this.state.new_entity.replace(/\s+/g,' ').trim();
        if(string != '' && !this.state.dataSource.includes(string) ){
           
           this.props.postEntity!(this.state.new_entity,this.state.access_token);
           this.setState({showText:true})
           this.setState({showLink:false})
        }else{console.log("No entity or Entity Exist")}
           this.setState({showText:true})
      }

    onSubmitForm=()=>{
        if(this.props.wit.msg.entities){
            {Object.keys(this.props.wit.msg.entities).map((k,i)=>{      
                this.props.wit.msg.entities[k].map(v=>{
                    if(k=='intent'){
                        this.state.submitValues.push({key:k,value:v.value,start:v._start,end:v._end})
                    }else{
                        this.state.submitValues.push({key:k,value:v._body,start:v._start,end:v._end})}    
                 })
              })
            }
        }
        if(this.state.msgEntities){
        this.state.msgEntities.map(v=>{
             console.log(this.state.submitValues.push({key:v.key,value:v.value,start:v.start,end:v.end}))
        })}
          this.props.createWitSample!(this.state.message,this.state.submitValues,this.state.access_token)
      
     }
     delete=(obj,index)=>{
        let array = this.state.msgEntities.filter( el => el !== obj);
        console.log(array)
        this.setState({msgEntities:array})
     }
     delete2=(k,v)=>{ 

        this.state.propsEntities = this.props.wit.msg.entities;
        var key = k;
        if(this.state.propsEntities[key].length>0){
            let array = this.state.propsEntities[key].filter( el => el !== v);
            this.state.propsEntities[key] = array;

        }else{
            delete this.state.propsEntities[key]
         // delete this.state.propsEntities.key; 
        }
        this.setState({style:{display:'none'}})
        console.log(this.state.propsEntities)
     }
    render(){
        const {getFieldDecorator} = this.props.form
        const text = this.props.wit.msg._text;

        return(
            <div className="witContent">
                <div className="top-content">
                    <h5>Test how your app understands a sentence</h5> 
                    <p>You can train your app by adding more examples</p>
                </div>
                <Form className="formClass">
                    <FormItem>
                    {getFieldDecorator('message',{
                        initialValue:this.state.message
                    })(
                        <Input type="text"
                            name="message"
                            className="msg-input"
                            placeholder="User says..."
                            onBlur={this.handleBlur}
                            onFocus={this.handleFocus}
                            onChange={this.handleInputChange}
                        />
                    )}
                    </FormItem>
                {this.state.showEntities &&
                <div>
                    {this.state.msgEntities.map((obj, index) => {
                        console.log(index)
                        return <p key={index}><Button  onClick={() => this.delete(obj,index)}>x</Button><b className="bClass"><span style={{paddingRight:100}}>{obj.key}</span> {obj.value}</b></p>
                    })}
                    

                    {/* display message available entities */}
                    {!_.isEmpty(this.props.wit.msg.entities) &&
                    <div>
                        <div className="tagger-suggested-header">
                              <span className="tagger-suggested-text">Other entities you might like</span>
                        </div>
                        {Object.keys(this.props.wit.msg.entities)
                        .map((keyName, v) => {
                            return <p key={v} style={{display:'flex',flexDirection:'column'}}>
                                {this.props.wit.msg.entities[keyName].map(v=>{
                                    console.log(keyName)
                                    if(keyName == 'intent'){
                                       return (
                                          <b key={v._end} style={this.state.style}  className="bClass">
                                          <Button onClick={()=>{this.delete2(keyName,v)}}>x</Button>
                                            <span style={{paddingRight:100}}>{keyName}</span>{v.value}
                                          </b>)
                                    }else{
                                       return (
                                          <b key={v._end} className="bClass">
                                          <Button onClick={()=>{this.delete2(keyName,v)}}>x</Button>
                                            <span style={{paddingRight:100}}>wit/{keyName}</span>{v._body}
                                          </b>)
                                    }
                            })}</p>
                        })}
                    </div>}
                </div>}
           
                <div style={{display:'flex'}}>
                <FormItem style={{ width: 200 }}>
                {getFieldDecorator('entity',{})(
                    
                    <AutoComplete
                        dataSource={this.state.dataSource}
                        placeholder="Add a new entity"
                        onChange={this.handleSelectChange}   
                        allowClear={true} 
                        filterOption={(inputValue, option) => option.props.children && option.props.children.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 }/>
                )}
                {this.state.showLink &&
                <Button className="linkBtn" onClick={this.createNewEntity}>Click to create new Entity <b><i>{this.state.new_entity}</i></b></Button>}
                </FormItem>
                {this.state.showInput &&
                <FormItem style={{ width: 200, marginLeft:50 }}>
                {getFieldDecorator('entity_value',{})(
                    <AutoComplete
                        dataSource={this.state.entity_values}
                        placeholder="select a value or create new"
                        onChange={this.handleSelectValueChange}
                        // onSearch={this.handleBlurvalue}
                        allowClear={true}
                        filterOption={(inputValue, option) => option.props.children && option.props.children.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}/>
                )}
                </FormItem>}
                {/* selection */}
                {this.state.showText && this.state.message &&
                <div>
                    <p>{this.state.highlightText}<br/><b><u><i>
                        <SelectionHighlighter
                        text={text}
                        value={this.state.message}
                        selectionHandler={this.selectionHandler}
                        customClass='custom-class'
                        /></i></u></b> 
                    </p>
                </div>
                 }
                </div>
                <FormItem>
                <Button className="sample-widget-validate-btn"
                        onClick={this.onSubmitForm}
                        disabled={this.state.disabled}>
                        <span><FontAwesomeIcon icon="check"/>  Validate</span>
                </Button>
                </FormItem>
            
            </Form>
            <Button onClick={()=>this.props.getAllSamples!(this.state.access_token)}>getAllSamples</Button>
            
            </div>
        )
    }
}











function mapStateToProps(state:any){
    return{
        user: state.reduxTokenAuth.currentUser,
        language:state.language,
        wit:state.witAi
    }
}
const mapDispatchToProps = {
    sendMsgToWit,getEntities,getEntityValues,postEntity,createWitSample,getAllSamples,deleteSample
}
const formWrapper = Form.create<any>()(WitAi)

export default connect<any>(mapStateToProps,mapDispatchToProps)(formWrapper)