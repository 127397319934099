import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Icon,Select, Input, Button,Radio, Row, Col, message, notification } from 'antd'; 
import { withRouter } from 'react-router-dom';
import { Switch } from 'antd';
import { API } from '../../api/api';
import { createProject,createApp,saveNlp,setProjectId,updateProject, setCurrentProject } from '../../actions';
import './project.css';
import {clone} from 'lodash'
import {FormComponentProps} from 'antd/lib/form/Form';
import FacebookPage from '../FacebookPage'
import axios from 'axios';
const FormItem = Form.Item;
const Option = Select.Option;

interface projectFormProps{
    form?:any;
    history?:any;
    isSignedIn?: Boolean;
    user? :any;
    projects?:any;
    projectId ?:any;
    createProject?: (values: any, callback: any) => void; 
    setCurrentProject?: (project: number) => void;
    createApp?:(values:any,lang:any)=>void;
    saveNlp?:(nlp: string)=>void;
    updateProject?: (project:any,values: any) => void;
    match?:any;
    project?:any;
    checkNick?: any;
    enableNlp?: true;
    language?:any;
    wit?:any
    nlp?:any
}
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: {span: 8}
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    md: {
      span: 16,
      offset: 8,
    },
  },
};
class projectForm extends React.Component<projectFormProps & FormComponentProps> {
  state:any ={
    confirmDirty: false,
    project:{},
    projectName:"",
    nlp_engine:{},
    selectedLanguage: 'Select language',
    nlp_token:'',
    isEdit: false,
    facebook_page_name: '',
    checkNick:'public'
  }

componentDidMount() {
  if (this.props.match.params.id) {
    API.getProject(this.props.user.attributes.id,this.props.match.params.id).then(async r => {
      if(r.data.nlp_engine){this.props.saveNlp!(r.data.nlp_engine[this.props.language.value])}
      let pageInfo
      if(r.data.facebook_page_id && r.data.facebook_page_access_token){
        pageInfo = await axios.get(`https://graph.facebook.com/${r.data.facebook_page_id}?access_token=${r.data.facebook_page_access_token}`)   
      }
      this.setState({
        project: r.data,
        nlp_engine: r.data.nlp_engine,
        checkNick: r.data.is_private? 'private': 'public',
        enableNlp: true,
        isEdit: true,
        facebook_page_name: await pageInfo.data.name
      })  
      
        
      
    });

  }
}

submitForm = (e: any) => {
  console.log('this.props',this.props)
    e.preventDefault();
    console.log(this.props.wit);
    this.props.form.validateFields((err, values) => {
    if (!err) {
      console.log('this.state.nlp_engine',this.state)

       values["nlp_engine"] = this.state.nlp_engine;
       values["is_private"] = this.state.checkNick ==='private';
       
       console.log('values', values)
      if (this.props.match.params.id){
        values["fallback_setting"] = JSON.parse(values["fallback_setting"]);
        this.props.updateProject!(this.props.match.params.id, values)
        notification.success({
          message:'updated successfully',
          description:''
        })
        // this.props.history.push(`/account`)
      }
      else{
        this.props.createProject!(values,(id)=>{
          this.props.history.push(`/project/${id}/accountHome`)
        });
      }
    }
  });
 
}

handleConfirmBlur = (e) => {
  const value = e.target.value;
  this.setState({ confirmDirty: this.state.confirmDirty || !!value });
}

handleRadioChange = (e) => {
    this.setState({
      checkNick: e.target.value ,
    }, () => {
      this.props.form.validateFields(['isPrivate'], { force: true });
    });
  }
handleSelectChang=(e)=>{
    console.log(e);
    this.setState({selectedLanguage:e})
  }
addNlp=()=>{
  const nlp_list =clone(this.state.nlp_engine)
  nlp_list[this.state.selectedLanguage] = this.state.nlp_token
  this.setState({nlp_engine: nlp_list, nlp_token:'', selectedLanguage:'Select language'})
}
deleteNlp=(lang)=>{
  const nlp_list = clone(this.state.nlp_engine)
  delete nlp_list[lang]
  this.setState({nlp_engine: nlp_list})
}
renderNlp=()=>{
  const { getFieldDecorator ,setFeildsValue } = this.props.form;
  return <div className='add-nlp'>
    <Row>
      <Col offset={8}  lg={{span:5, offset:8}}>
          <Select
                style={{width:'100%'}}
                showSearch
                optionFilterProp="children"
                // defaultValue= {this.state.selectedLanguage}
                value={this.state.selectedLanguage}
                onChange={this.handleSelectChang}>
                <Option value="en">English</Option>
                <Option value="sv">Swedish</Option>
          </Select>
      </Col>
      <Col lg={{span:7, offset:1}}>
          <Input  
                placeholder="Example: H4MDFG2345JK"    
                onChange={(e) => {this.setState({nlp_token:e.target.value})}}
                value={this.state.nlp_token}/>            
      </Col>
      <Col lg={{span:2}}>
        <Button style={{margin: 8}} onClick={this.addNlp} className='bot-btn primary'>
          Add
        </Button>
      </Col>
    </Row>
    <div className='nlp-list'>
      {Object.keys(this.state.nlp_engine).length >0 && <Row >
          <Col offset={8} md={4}>
            <strong>Language</strong>
          </Col>
          <Col md={11}>
            <strong>Access Token</strong>
          </Col>
        </Row>}
      {Object.keys(this.state.nlp_engine).map(x=>{
        const item = this.state.nlp_engine[x]
        return <Row key={x}>
          <Col offset={8} md={4}>
            <h3 style={{overflowWrap: 'break-word'}}>{x}</h3>
          </Col>
          <Col md={11}>
            <h3>{item}</h3>
          </Col>
          <Col md={1}>
            <span style={{cursor:'pointer'}} onClick={()=>this.deleteNlp(x)}><Icon type="delete" /></span>
          </Col>
        </Row>
      })}
    </div>
      

            
            
  </div>
}


render(){
  const isEdit = this.props.match.params.id
  console.log('p',this.state.project)
      const { getFieldDecorator ,setFeildsValue } = this.props.form;
      
  return(
      <div className={`create-project ${isEdit&&'edit'}`}>
        <h1 className='title'>{isEdit ? this.state.project.name : 'New Project'}</h1>
        <Form className='login-form' onSubmit={this.submitForm}>
            <FormItem {...formItemLayout} label='Project name'>
                {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Please input your Project name !' }],
                    initialValue: this.state.project && this.state.project.name})(
                    <Input prefix={<Icon type='user' 
                           style={{ color: 'rgba(0,0,0,.25)' }}  />}  
                           placeholder="Bot Name" 
                           onChange={(e)=>{this.setState({projectName:e.target.value})}}/>)}
            </FormItem>
            <FormItem {...tailFormItemLayout} id='Checkbox'>
              {getFieldDecorator('is_private', { 
                      initialValue: this.state.checkNick})(
                      <Radio.Group onChange={this.handleRadioChange}>
                      <Radio value='public'>
                        <div>
                          <span className='radio-label'> Public </span>
                          <p className ='radio-description'>Your bot is open to everyone</p>
                        </div>
                       
                      </Radio>
                      <Radio value={'private'}>
                        <div>
                          <span className='radio-label'> Private </span>
                          <p className ='radio-description'>Your bot is accessable only by you and the authors you decided to share with</p>
                        </div>
                        
                      </Radio>
                    </Radio.Group>)}
            </FormItem>
            <div className="nlpClass">            
              <FormItem {...formItemLayout} label='Nlp engine'>
                    <img width={60} src='/assets/images/wit-logo.png'/>
                    <span style={{fontSize: 24,marginLeft: 30}}> Wit.ai </span>
                    <Switch checked={this.state.enableNlp} onChange={(e)=>this.setState({enableNlp: e})} />

              </FormItem>

              {this.state.enableNlp && this.renderNlp()}
            
            {/* {isEdit && <FormItem {...formItemLayout} label='Facebook Page'>
            {getFieldDecorator('facebook_page_access_token', {
                    rules: [{ required: false, message: 'Please input your Facebook_Page !'}],
                    initialValue: this.state.project && this.state.project.facebook_page_access_token})(
                   <Input prefix={<Icon type='url'
                          style={{ color: 'rgba(0,0,0,.25)' }}/>}
                          placeholder="Facebook_Page"/>)}
            </FormItem>} */}

            {isEdit && <FormItem {...formItemLayout} label='fallback setting '>
            {getFieldDecorator('fallback_setting', {
                    rules: [{ required: false, message: 'Please input your fallback_setting  !'}],
                    initialValue: this.state.project && JSON.stringify(this.state.project.fallback_setting)})(
                    <Input prefix={<Icon type='user' 
                          style={{ color: 'rgba(0,0,0,.25)' }} />}  
                          placeholder="fallback_setting "/>)}
            </FormItem>}

            {isEdit && <div className='fb-page'>
              {this.state.facebook_page_name && <h3>Connected to facebook page: <strong>{this.state.facebook_page_name}</strong></h3>}
              <FacebookPage
                  appId={process.env.REACT_APP_APP_ENVIRONMENT === 'prod' ? '1069264536768777' : '806866736490295'}
                  autoLoad={false}
                  callback={ response => {
                    axios.get(`https://graph.facebook.com/${response.userID}/accounts?access_token=${response.accessToken}`)
                    .then(r => {
                      let pageInfo = r.data.data[0]
                      let data = {facebook_page_id: pageInfo.id, facebook_user_id: response.userID, facebook_page_access_token: pageInfo.access_token}
                      API.setFacebookAccessToken( this.props.user.attributes.id, this.props.match.params.id , data).then(res=>{
                        this.setState({facebook_page_name:pageInfo.name})

                      })
                    })                
                  }}
                  disableMobileRedirect={true}
              /></div>}

            <Button htmlType='submit' disabled={this.state.disabledButton} className='login-form-button'>
                {isEdit? 'Update':'Create'}
            </Button>

            </div>                    
      </Form>
       <div>
    </div>
  </div>
     )}
  }

function mapStateToProps(state: any) {
  return {
    user: state.reduxTokenAuth.currentUser,
    projects:state.projects,
    project:state.project,
    language: state.language,
    wit:state.witAi,
    nlp:state.witAi.nlp
  };
}
const mapDispatchToProps = {
    createProject,
    setProjectId,
    updateProject,
    createApp,
    saveNlp,
    setCurrentProject
};

const WrappedNormalRegisterForm = Form.create<projectFormProps>()( withRouter( projectForm ));
export default connect<any,any>(mapStateToProps,mapDispatchToProps)(WrappedNormalRegisterForm);
