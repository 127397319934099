import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button, Checkbox, Row, Col, notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { signInUser } from '../authTokenConfig';
import { RcBaseFormProps } from 'antd/lib/form/Form';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { API } from '../api/api';
import Utils from '../../Utils'
import queryString from 'query-string';
const FormItem = Form.Item;

const { Header, Content, Footer } = Layout;

interface IResetProps {
  signInUser?: (dispacth: any) => Promise<any>;
  form: any;
  history: any;
  location:any
};


class ResetPassword extends React.Component<IResetProps> {
  state ={
    confirmDirty: false,
    loading: false

  }
  submitForm(e: any) {
    this.setState({loading: true})
    e.preventDefault();
    let queryParams = Utils.parseQueryString(this.props.location.search)
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let data ={password: values.password,
                   password_confirmation: values.passwordConfirmation,}
        let headers ={'client': queryParams.client_id, 
                    'access-token': queryParams.token, 
                    'uid': queryParams.uid.replace('%40','@')}
        API.resetPassword(data, headers).then(r=>{
          this.setState({loading: false})
          notification.success({
            message:'Password has been changed successfully',
            description:'',
            className:'auth-msg'
          })
          this.props.history.push('/signin')
        })
        .catch(e=>{
          this.setState({loading: false})
          notification.error({
            message:'Password changed faield',
            description:e.response.data.errors,
            className:'auth-msg'
          })
        })
      }
    });
  }


  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
   
    } else {
      callback();
    }
  }
 
 validateToNextPassword = (rule, value, callback) => {
     const form = this.props.form;
     if (value && this.state.confirmDirty) {
       form.validateFields(['confirm'], { force: true });
     }
     else if(value.length < 8){
      callback('Your password must be at least 8 characters long');
    }
     callback();
   }


  

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className='reset-password auth-screen'>
        <Row>
          <Col md={10} className='content'>
            <div className='logo'>
                <img src='/assets/images/logo.png'/>
                <h1> Build Bots with ease </h1>
            </div>
        
            <Form onSubmit={e => this.submitForm(e)} className='form' layout='vertical'>
              <h3>Enter New password.</h3>
              <FormItem>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input your password!' },{
                          validator: this.validateToNextPassword,
                        }],
              })(
                  <Input prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} type='password' placeholder='Password' />
            )}
              </FormItem>

              <FormItem >
              {getFieldDecorator('passwordConfirmation', {
                rules: [{ required: true, message: 'Please input your password!' },{
                          validator: this.compareToFirstPassword,
                        }],
              })(
                  <Input prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} type='password' placeholder='Rewrite Password' />
                )}
              </FormItem>


              <FormItem>
              <Button loading={this.state.loading} htmlType='submit' className='submit'>
                Reset
              </Button>
              {/* <div className='link'>
                <Link to='/signin'>
                    <span>Return to sign in</span>
                </Link>
              </div>         */}
              </FormItem>
            </Form>
            </Col>
          <Col md={14}>
            <div className=''>
              <img width='100%' src='/assets/images/homeBg.png'/>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(ResetPassword);

export default connect<RcBaseFormProps & Pick<IResetProps, 'signInUser'>>(
  null,
  { signInUser },
)(withRouter(WrappedNormalLoginForm));
