import * as React from 'react';
import { connect } from 'react-redux';
import { Row } from 'antd';

import * as _ from 'lodash';
import * as d3 from 'd3';

import Contexts from './contexts';
import Dialogue from './dialogue';
import Arc from './arc';
import Chat from './chat';
import AddParent from './addParent';
import { Mode } from '../../../constants/types';
import { addParent, setSelectedArc, setSelectedDialogue } from '../../../actions';

interface IBotProps {
  dialogues?: any[];
  addParent?: ( parentId: number ) => void;
  mode?: Mode;
  setSelectedDialogue?: (dialogue: any) => void;
  selectedDialogue? : any;
  setSelectedArc?: (arc: any) => void;
  selectedArc? : any;
  language?: any;
};

interface IBotState { };

class Bot extends React.Component<IBotProps, IBotState> {
  
  svg; g; root; rect; nodesMap; tree?: (node: any) => any;
  width; height; duration = 400;

  MARKER_CLASS_END = '_marker';
  MARKER_CLASS_END_RED = 'red_marker';
  SPACE_BETWEEN_DEPTH_LEVELS = 160;
  MARKER_CSS_STYLES = {
    id: 'arrow',
    viewBox: '0 -5 10 12',
    refX: 16,
    refY: 0,
    markerWidth: 6,
    markerHeight: 6,
    d: 'M0,-5L10,0L0,5',
    orient: 'auto-start-reverse'
  };

  diagonal = d3.linkVertical()
    .x(function(d: any) { return d && d.x; })
    .y(function(d: any) { return d && d.y; });

  nodeClick = (d) => {
    if (this.props.mode === Mode.addParent) {
      this.props.addParent!(d.data.id);
      return;
    }
    if (this.props.mode === Mode.chat) { return; }
    let node = this.g.selectAll('g.node');
    node.classed('selected', n => n.id === d.id );
    this.props.setSelectedDialogue!(d.data.id);
  }

  arcClick = (d) => {
    if (this.props.mode === Mode.chat || this.props.mode === Mode.addParent) { return; }
    let parentIndex = d.data.parents_id.findIndex(e => e.id === d.parent.data.id);
    let arcId = d.data.arcs[parentIndex];
    this.props.setSelectedArc!({id: arcId, child: d.data.id, parent: d.parent.data.id});
  }

  componentDidMount() {

    if (this.props.dialogues) {
      this.drawTree();
    }

    window.addEventListener('resize', () => {
      let chartDiv:any = document.getElementById('graph');
      this.width = chartDiv.clientWidth;
      this.height = chartDiv.clientHeight;
      // use the extracted size to set the size of an SVG element.
      this.svg
      .attr('width', this.width)
      .attr('height', this.height);

      this.rect
      .attr('width', this.width)
      .attr('height', this.height);

      this.update(this.root, 0);
    });
  }

  componentDidUpdate(prevProps) {

    if (this.props.dialogues && !_.isEqual(this.props.dialogues, prevProps.dialogues)) {
      this.drawTree();
    }
    if (this.props.selectedDialogue) {
      let node = this.g.selectAll('g.node');
      node.classed('selected', n => n.data.id === this.props.selectedDialogue );
    }
  }

  pathDiagonal = function(d: any) {return 'M ' + d.source.x + ' ' + d.source.y + ' L ' + d.target.x + ' ' + d.target.y; };

  reduceArray(arr) {
    return arr.reduce(function (map, item) {
      map[item.id || item.data.id] =  item;
      return map;
    }, {});
  }

  generateTree(realData) {
    let data = JSON.parse(JSON.stringify(realData)),
    dataMap = this.reduceArray(data),
    treeData: any[] = [];

    // adding data-target attribute with id's of targets of every node
    data.forEach(function (node:any, index) {
      node.index = index;
      if (node.parents_id && !node.intent) {
        let parentLength = node.parents_id.length;
        node.parents_id.forEach(function (parentItem, index) {
          let parent = dataMap[parentItem.id] || {};
          if (parentLength > 1 && index !== parentLength - 1) {
            return;
          }
          parent.children =  parent.children || [];
          parent.children.push(node);
        });
      } else {
        treeData.push(node);
      }
    });

    return treeData[0];
  }

  addSpecialParent(nodes) {
    nodes.each(d => {
      let sources = d.data.parents_id;
      if (sources && sources.length > 1) {
        for (let i = 0; i < sources.length - 1; i++) {
          if (!this.nodesMap[sources[i].id]) { continue; }
          let curLink = {
            source: this.nodesMap[sources[i].id],
            target: d
          };
          let parent = {data: this.nodesMap[sources[i].id].data};
          let parentIndex = d.data.parents_id.findIndex(e => e.id === this.nodesMap[sources[i].id].data.id);
          this.g.insert('path', 'g')
          .attr('class', 'link special')
          .attr('d', () => {
            return this.pathDiagonal(curLink);
          })
          .attr('id', 'edgePathSpecial' + i)
          .style('stroke', d && d.data.conditions[parentIndex] && d.data.conditions[parentIndex].length === 0 ? 'red' : '#ccc')
          .on('click', () => this.arcClick({data: d.data, parent: parent}))
          .attr('marker-start', d && d.data.conditions[parentIndex] && d.data.conditions[parentIndex].length === 0 ? 'url(#' + this.MARKER_CLASS_END_RED + ')' :
              'url(#' + this.MARKER_CLASS_END + ')');

          this.g.insert('text', 'g')
          .style('pointer-events', 'none')
          .attr('class', 'edgelabel special')
          .attr('dx', 10)
          .attr('dy', -5)
          .attr('font-size', 10)
          .attr('fill', '#aaa')
          .append('textPath')
          .attr('xlink:href', function(_d, i) {return '#edgePathSpecial' + i; })
          .style('text-anchor', 'middle')
          .style('pointer-events', 'none')
          .attr('startOffset', '50%')
          .text(d.data.conditions[parentIndex] && d.data.conditions[parentIndex].map(c => {
            let s = '';
            if (c.option) {
              s = c.option.content[this.props.language];
            } else if (c.parameter) {
              let p = c.parameter;
              s = p.entity === 'intent' ? p.value : p.entity + ': ' + (p.value || (p.min === p.max ? p.min : p.min + '-' + p.max));
            }
            return s;
          }).join(', '));
        }
      }
    });
  }

  hasChildren(d) {
    return d.data.children && d.data.children.length > 0;
  }

  renderTree(rootGenerated) {

    this.tree = d3.tree().nodeSize([200, 20]);
    // .size([this.width, this.height]);

    this.svg = d3.select('#graph').append('svg')
    .attr('class', 'everything')
    .attr('width', this.width)
    .attr('height', this.height);


    this.rect = this.svg.append('rect').attr('width', this.width)
    .attr('height', this.height)
    .attr('fill', 'transparent');

    this.g = this.svg.append('g');

    // append arrow
    this.svg.append('svg:defs').selectAll('marker')
    .data([this.MARKER_CLASS_END, this.MARKER_CLASS_END_RED])
    .enter().append('svg:marker')
    .attr('id', String)
    .attr('class', String)
    .attr('viewBox', this.MARKER_CSS_STYLES.viewBox)
    .attr('refX', this.MARKER_CSS_STYLES.refX)
    .attr('refY', this.MARKER_CSS_STYLES.refY)
    .attr('markerWidth', this.MARKER_CSS_STYLES.markerWidth)
    .attr('markerHeight', this.MARKER_CSS_STYLES.markerHeight)
    .attr('orient', this.MARKER_CSS_STYLES.orient)
    .append('svg:path')
    .attr('d', this.MARKER_CSS_STYLES.d)
    .attr('class', 'arrowHead');

    this.root = d3.hierarchy(rootGenerated, function(d) { return d.children; });
    this.root.y0 = 0;
    this.root.x0 = 0;
    this.update(this.root, 0);
    this.centerGraph();
  }


  update(source, specialDuration) {

    // assigns the x and y position for the nodes
    let treeData = this.tree!(this.root);

    // compute the new tree layout.
    let nodes = treeData.descendants(),
    links = treeData.descendants().slice(1);

    this.nodesMap = this.reduceArray(nodes);

    // normalize for fixed-depth.
    nodes.forEach((d) => {
      d.y = d.depth * this.SPACE_BETWEEN_DEPTH_LEVELS;
    });

    // ****************** Nodes section ***************************

    // update the nodes...
    let i = 0;
    let node = this.g.selectAll('g.node')
    .data(nodes, function(d) {return d.id || (d.id = ++i); });

    // enter any new modes at the parent's previous position.
    let nodeEnter = node.enter().append('g')
    .attr('class', 'node')
    .attr('transform', function(d) {
      return 'translate(' + source.x0 + ',' + source.y0 + ')';
    })
    .on('mouseover', (d) => {
      this.fade(0.4)(d);
    })
    .on('mouseout', (d) => {
      this.fade(1)(d);
    })
    .on('click', this.nodeClick)
    .on('dblclick', this.dblClick);

    // add Circle for the nodes
    nodeEnter.append('circle')
    .attr('class', d => d.data.id === -1 ? 'node hidden' : 'node')
    .attr('r', 1e-6)
    .style('fill', function(d) {
      return d._children ? 'lightsteelblue' : '#fff';
    });

    // add labels for the nodes
    nodeEnter.append('text')
    .attr('dy', '.35em')
    .attr('y', (d) => { return this.hasChildren(d) ? (d.parent ? -18 : 0) : 18; })
    .attr('x', function(d) { return d.parent ? 0 : 15; })
    .style('text-anchor', function(d) { return d.parent ? 'middle' : 'start' ;})
    .text(function(d) {console.log(d.data.name.en); return d.data.name;});


    // remove any exiting nodes
    let nodeExit = node.exit().transition()
    .duration(this.duration)
    .attr('transform', function(d) {
      return 'translate(' + source.x + ',' + source.y + ')';
    })
    .remove();

    // on exit reduce the node circles size to 0
    nodeExit.select('circle')
    .attr('r', 1e-6);

    // on exit reduce the opacity of text labels
    nodeExit.select('text')
    .style('fill-opacity', 1e-6);

    // update
    let nodeUpdate = nodeEnter.merge(node);

    // transition to the proper position for the node
    nodeUpdate.transition()
    .duration(this.duration)
    .attr('transform', function(d) {
      return 'translate(' + d.x + ',' + d.y + ')';
    });

    // update the node attributes and style
    nodeUpdate.select('circle.node')
    .attr('r', 10)
    .style('fill', function(d) {
      return d._children ? 'lightsteelblue' : '#fff';
    })
    .attr('cursor', 'pointer');

    // ****************** links section ***************************
    // update the links...
    let j = 0;
    let link = this.g.selectAll('path.link')
    .data(links, function(d) {
      if (!d) {d = {}; }
      return d.id || (d.id = ++j);
    });

    // enter any new links at the parent's previous position.
    let linkEnter = link.enter().insert('path', 'g')
    .attr('class', d => (d.parent && d.parent.data.id === -1) ? 'link hidden' : 'link')
    .attr('id', (_d, i) => 'edgePath' + i)
    .style('stroke', d => {
      let parentIndex = d.data.parents_id.findIndex(e => e.id === d.parent.data.id)
      return d && d.data.conditions[parentIndex] && d.data.conditions[parentIndex].length === 0 ? 'red' : '#ccc'; })
    .attr('d', (_d) => {
      let curLink = {
        source: source,
        target: source
      }
      return this.diagonal(curLink);
    })
    .on('click', this.arcClick)
    .attr('marker-start', d => {
      let parentIndex = d.data.parents_id.findIndex(e => e.id === d.parent.data.id)
      return d && d.data.conditions[parentIndex] && d.data.conditions[parentIndex].length == 0 ? 'url(#' + this.MARKER_CLASS_END_RED + ')' :
        'url(#' + this.MARKER_CLASS_END + ')'; });

    let edgelabel = this.g.selectAll('text.edgelabel')
    .data(links);
    let edgelabelEnter = edgelabel.enter()
    .append('text')
    .style('pointer-events', 'none')
    .attr('class', d => (d.parent && d.parent.data.id === -1) ? 'edgelabel hidden' : 'edgelabel')
    .attr('id', (_d, i) => 'edgelabel' + i)
    .attr('dx', 10)
    .attr('dy', -5)
    .attr('font-size', 9)
    .attr('fill', '#aaa');

    edgelabelEnter.append('textPath')
      .attr('xlink:href', function(_d, i) {return '#edgePath' + i; })
      .style('text-anchor', 'middle')
      .style('pointer-events', 'none')
      .attr('startOffset', '50%')
      .text((d, _i) => {
        let parentIndex = d.data.parents_id.findIndex(e => e.id === d.parent.data.id);
        return d.data.conditions[parentIndex] && d.data.conditions[parentIndex].map(c => {
          let s = '';
          if (c.option){
            s = c.option.content[this.props.language];
          } else if (c.parameter) {
            let p = c.parameter;
            s = p.entity === 'intent' ? p.value : p.entity + ': ' + (p.value || (p.min === p.max ? p.min : p.min + '-' + p.max))
          }
          return s;
        }).join(', ');
      });

    // remove any exiting links
    link.exit().remove();
    edgelabel.exit().remove();


    // update
    let linkUpdate = linkEnter.merge(link);
    let edgelabelUpdate = edgelabelEnter.merge(edgelabel);

    // transition back to the parent element position
    linkUpdate.transition()
    .duration(this.duration)
    .attr('d', (d) => {
      let curLink = {
        source: d,
        target: d.parent
      };
      return this.diagonal(curLink);
    });
    edgelabelUpdate.transition().duration(this.duration);
    this.g.selectAll('.special').remove();
    setTimeout(() => {this.addSpecialParent(nodeUpdate);
      this.centerGraph()
    }, specialDuration);
    // store the old positions for transition.
    nodes.forEach(function(d){
      d.x0 = d.x;
      d.y0 = d.y;
    });

  }

  centerGraph = () => {
    this.g.selectAll(':not(.edgelabel)').attr('transform', (d) => {
      return 'translate(' + this.width / 2 + ',' + 0 + ')';
    });
  }

  // toggle children on click.
  dblClick = (d) => {
    if (d.children) {
      d._children = d.children;
      d.children = null;
    } else {
      d.children = d._children;
      d._children = null;
    }
    this.update(d, this.duration / 2);
    setTimeout( () => {this.update(d, 0);
      this.centerGraph();
    }, this.duration);
  }

  fade(opacity) {
    return (node) => {
      this.svg.selectAll('.node')
      .filter((d) => (d.data.id !== node.data.id))
      .transition()
      .style('opacity', opacity);
    };
  };

  // zoom functions
  zoom_actions = () => {
    this.g.attr('transform', d3.event.transform);
  }

  drawTree() {
    let hiddenParent = {id: -1, name: '', parents_id: null}
    let dialogues:any = _.clone(this.props.dialogues);
    dialogues.forEach(l => {
      if (l.parents_id == null) {
        l.parents_id = [{id: -1}];
      } else {
        l.parents_id.forEach(parent => {
          if (parent.id == null) {
            parent.id = -1;
          }
        });
      }
    });
    dialogues.push(hiddenParent);
    let chartDiv:any = document.getElementById('graph');
    chartDiv.innerHTML = '';

    this.width = chartDiv.clientWidth;
    this.height = chartDiv.clientHeight;

    let treeGenerated = this.generateTree(dialogues);

    this.renderTree(treeGenerated);

    let zoom_handler = d3.zoom().scaleExtent([0.5, 3])
      .on('zoom', this.zoom_actions);
    zoom_handler(this.svg);
    this.svg.on('dblclick.zoom', null);
  }


  render() {
    console.log('bottt')
    return (
      <div style={{height: '1000px'}}>
        <Row type='flex' justify='center' style={{paddingTop: 5}}>
          <h1>Dialogue Tree</h1>
        </Row>
        <div id='graph'>
        </div>
        <Contexts />
        <Dialogue />
        <Arc />
        <Chat />
        <AddParent />
      </div>
    );
  }
}


function mapStateToProps(state: any) {
  let {selectedArc, selectedDialogue, mode} = state.bot;
  return {
    dialogues: state.bot.dialogues,
    mode,
    language: state.language.value,
    selectedArc, selectedDialogue
  };
}

const mapDispatchToProps = {
  addParent,
  setSelectedArc,
  setSelectedDialogue,
};

export default connect<IBotProps>(
  mapStateToProps,
  mapDispatchToProps
)(Bot);
