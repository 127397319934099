import { Layout, Menu, Breadcrumb } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button,List,Dropdown } from 'antd';
import { withRouter } from 'react-router-dom';
import UsersList from './UsersList';
import Account from './Project/Account';
import { Link } from 'react-router-dom';
import { changeLanguage,setCurrentProject } from '../actions';
import { signOutUser } from '../authTokenConfig';
import PageHeader from "./Header";
const SubMenu = Menu.SubMenu;
const { Header, Content, Footer, Sider } = Layout;


interface ProjectMenuProps {
  currentProject? : number;
  signOutUser?: (dispacth: any) => Promise<any>;
  projects?:any;
  setCurrentProject?: (dispatch: any) => void;
  ind?: number;
  location?:any;
  history?:any;
}
interface PassedProps extends React.Props<any> {
}

class ProjectMenu extends React.Component<ProjectMenuProps& PassedProps, any > {

  state = {
    collapsed: true,
  };

  pro = 0;
  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  }

  render(){
    const pathname = this.props.location.pathname
    const reg = /\/([a-z]+)\/(\d+)\/([a-z]+)/ig.exec(pathname)
    const state = reg ? reg[3] : "0"
    // TOHANDLE non project pages
    const isProjectPage = true
    return (
        <Layout className="pageMenu">
          <Sider
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
          >
            <div className="logo" />
            <Menu theme="dark" defaultSelectedKeys={[state]} mode="inline">
                <Menu.Item key='0'><Link style={{color: '#FAEBD7' }} to={'/account'}>  <img className='anticon' src='/assets/images/home.svg'/><span> Home</span></Link></Menu.Item>
                {isProjectPage && <Menu.Item key='accountHome'><Link style={{color: '#FAEBD7' }} to={'/project/'+this.props.currentProject +'/accountHome'}>  <span> <img className='anticon' src='/assets/images/file.svg'/><span> Upload</span></span></Link></Menu.Item>}
                {isProjectPage && <Menu.Item key='bot'><Link style={{ color: '#FAEBD7' }} to={'/project/'+this.props.currentProject +'/bot'} ><span><img className='anticon' src='/assets/images/workflow.svg'/><span> Bot</span></span></Link></Menu.Item>}
                {isProjectPage && <Menu.Item key='users'><Link style={{ color: '#FAEBD7' }} to={'/project/'+this.props.currentProject+'/users'}><span><img className='anticon' src='/assets/images/group.svg'/><span> Users</span></span> </Link></Menu.Item>}
                {isProjectPage && <Menu.Item key='edit'><Link style={{ color: '#FAEBD7' }} to={'/project/'+this.props.currentProject +'/edit'}><span><img className='anticon' src='/assets/images/settings.svg'/><span> Edit</span></span></Link></Menu.Item>}
            </Menu>
          </Sider>
        </Layout>
    );
  }
}
function mapStateToProps(state: any) {
  return {
      user: state.reduxTokenAuth.currentUser,
      projects:state.projects,
      users:state.users,
      currentProject: state.bot.currentProject,
      ind: state.projects.findIndex(e => e.id === state.bot.currentProject),

  };
}

const mapActionsToProps = {setCurrentProject,signOutUser
};



export default connect<{},{},PassedProps>(mapStateToProps,mapActionsToProps)(withRouter(ProjectMenu));
