import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button, Checkbox, Row, Col, notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { signInUser } from '../authTokenConfig';
import { RcBaseFormProps } from 'antd/lib/form/Form';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
const FormItem = Form.Item;

const { Header, Content, Footer } = Layout;

interface ISignInProps {
  signInUser: (dispacth: any) => Promise<any>;
  form: any;
  history: any;
};


class SignInScreen extends React.Component<ISignInProps> {
  state={
    loading: false
  }
  submitForm(e: any) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loading: true})
        const { signInUser } = this.props;
        signInUser({ email: values.email, password: values.password }).then(r => {
          console.log('signed in', r);
          this.setState({loading: false})
          this.props.history.push('/account');
        }).catch(e => {
          this.setState({loading: false})
          notification.error({
            message:'Login failed',
            description:e.response.data.errors,
            className:'auth-msg'
          })
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className='sign-in auth-screen'>
        <Row>
          <Col md={10} className='content'>
            <div className='logo'>
                <img src='/assets/images/logo.png'/>
                <h1> Build Bots with ease </h1>
            </div>
            <Form onSubmit={e => this.submitForm(e)} className='form' layout='vertical'>
            <h3>Welcome! Please login to your account.</h3>
            <FormItem >
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Please input your email!' }],
              })(
                <Input prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Email' />
              )}
            </FormItem>
            <FormItem >
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input your Password!' }],
              })(
                <Input prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} type='password' placeholder='Password' />
              )}
            </FormItem>
            <FormItem>
            <div className='remember'>
              {/* <Checkbox>Remember me</Checkbox> */}
              <Link to="/forgotPassword">
                <span >Forgot Password</span>
              </Link>
            </div>
            <Button loading={this.state.loading} htmlType='submit' className='submit'>
              Sign in
            </Button>
            <div className='link'>
              <Link to='/signup'>
                  <span>New user? create new account</span>
              </Link>
            </div>        
            </FormItem>
          </Form>
          </Col>
          <Col md={14}>
            <div className=''>
              <img width='100%' src='/assets/images/homeBg.png'/>
            </div>
          </Col>
        </Row>
       
        
        

      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignInScreen);

export default connect<RcBaseFormProps & Pick<ISignInProps, 'signInUser'>>(
  null,
  { signInUser },
)(withRouter(WrappedNormalLoginForm));
