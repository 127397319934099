import * as React from 'react';
import { Menu, Dropdown, Icon, Button, Modal, Select, Input, Radio, Row, Col ,Form} from 'antd';
import { API } from '../../../api/api';
import axios, { AxiosPromise } from 'axios';
import { connect } from 'react-redux';
import { changeLanguage } from '../../../actions';

const Option = Select.Option;

interface IResponseProps {
  visible?: boolean;
  response?: number;
  variable?: number;
  option?: number;
  dialogue?: number;
  onCancel: () => void;
  language?: any;
  changeLanguage?: (language: any) => void;
  onSave?: () => void;
};

interface IResponseState {
  toRemove: any[];
  response: {id: (number | null), contents: any[]};
  curKey: number;
};

class Response extends React.Component<IResponseProps, IResponseState> {
  state = {
    toRemove: [] as any,
    response: {id: null, contents: [] as any},
    curKey: 0
  };

  componentDidMount(){
    this.saveIfRequired(this.state.response);
  }


  componentDidUpdate(prevProps) {
    let {visible, response, variable, option, dialogue} = this.props;
    if (visible && !prevProps.visible) { // it became visible
      if (response) {
        let req = variable ? API.getVariableResponse(dialogue, variable, response, option) : API.getDialogueResponse(dialogue, response, option);
        req.then((r: any) => {
          let response = r.data.response;
          response.contents = r.data.contents.map(e => {e.index = this.state.curKey++; return e; });
          this.setState({response, curKey: this.state.curKey});
        });
      } else {
        this.setState({toRemove: [], response: {id: null, contents: []}, curKey: 0});
      }
    }
  }

  saveIfRequired = (option?) => {
    if (!this.props.variable) {
      this.ok();
    } else if (option) {
      this.save();
    }
  }

  addResponseRow = () => {
    this.state.response.contents.push({ index: this.state.curKey, content_type: 'text', content: {} });
    this.setState({response: this.state.response, curKey: this.state.curKey + 1});
  }
  removeResponseRow = (rowId) => {
    var index = -1;
    for (var i = 0; i < this.state.response.contents.length; i++) {
      if (this.state.response.contents[i].index === rowId) {
        index = i;
        if (this.state.response.contents[i].id) {
          this.state.toRemove.push(this.state.response.contents[i]);
        }
        break;
      }
    }
    this.state.response.contents.splice(index, 1);
    this.setState({response: this.state.response, toRemove: this.state.toRemove});
  }

  save = () => {
    let {variable, option, dialogue} = this.props;
    let promises: AxiosPromise<any>[] = [];
    this.state.toRemove.forEach(element => {
      promises.push(API.deleteResponseContent(dialogue, this.state.response.id, element.id, variable, option));
    });

    this.state.response.contents.forEach(content => {
      if (content.id) {
        promises.push(API.editResponseContent(dialogue, this.state.response.id, content, variable, option));
      } else {
        promises.push(API.addResponseContent(dialogue, this.state.response.id, content, variable, option).then(r => {
          content.id = r.data.id;
          console.log(r)
          this.setState({response: this.state.response});
          return r;
        }));
      }
    });

    this.setState({toRemove: []});

    axios.all(promises).then(this.props.onSave);
  }

  ok = () => {
    if (this.state.response.contents.length === 0 && this.state.toRemove.length === 0) {
      this.props.onCancel();
      return;
    }
    let {variable, option, dialogue} = this.props;
    if (!this.state.response.id) {
      API.addResponse(dialogue, variable, option).then((r: any) => {
        this.state.response.id = r.data.id;
        this.setState({response: this.state.response});
        this.save();
      });
    } else {
      this.save();
    }
  }
  submitForm = (e: any) => {
    e.preventDefault();
  }

  render() {
    const { visible, onCancel } = this.props;
    const selectBefore = (i) => (
      <Select value={this.state.response.contents[i].content_type} style={{ width: 100 }}
        onChange={(e) => { this.state.response.contents[i].content_type = e;
          this.setState({response: this.state.response}); }}>
        <Option value='text'>Text</Option>
        <Option value='image'>Image</Option>
        <Option value='video'>Video</Option>
      </Select>
    );
    return (
      <Modal
        visible={visible}
        closable={false}
        title={

        <div>
            Response
            <Radio.Group value={this.props.language} onChange={this.props.changeLanguage} style={{position: 'absolute', right: 12, top: 12}}>
              <Radio.Button value='en'>English</Radio.Button>
              <Radio.Button value='sv'>Swedish</Radio.Button>
            </Radio.Group>
          </div>
        }

        okText='Save'
        onCancel={onCancel}
        onOk={this.ok}
      >

      Please enter different response variations
      {this.state.response.contents.map((content, i) => (
            <div key={'response' + i} style={{ paddingBottom: 12}}>
              <Input addonBefore={selectBefore(i)} value={content.content[this.props.language]} style={{width: '90%'}}
                onChange={(e) => {
                  this.state.response.contents[i].content[this.props.language] = e.target.value;
                  this.setState({response: this.state.response}); }} />
              <Icon style={{ float: 'right', marginTop: -4 }}
                className='dynamic-delete-button'
                type='minus-circle-o'
                onClick={() => this.removeResponseRow(content.index)}
              />
            </div>
          ))}
          <Row type='flex' justify='center'><Col span={20}>
            <Button type='dashed' onClick={() => this.addResponseRow()} style={{ width: '100%', marginBottom: 15 }}>
              <Icon type='plus' /> Add response variation
            </Button>
          </Col></Row>
          {console.log(this.state.response)}

      </Modal>
    );
  }
}


function mapStateToProps(state: any) {
  return {
    dialogue: state.bot.selectedDialogue,
    language: state.language.value
  };
}

const mapDispatchToProps = {
  changeLanguage
};

export default connect<any>(mapStateToProps, mapDispatchToProps)(Response);
