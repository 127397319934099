import * as React from 'react';
import { Menu, Dropdown, Icon, Button, Modal, Select, Input, Radio, Row, Col, Divider ,Form} from 'antd';
import { API } from '../../../api/api';
import axios, { AxiosPromise } from 'axios';
import { connect } from 'react-redux';
import { changeLanguage } from '../../../actions';
import Response from './response';

interface IVariableProps {
  visible?: boolean;
  variable?: number;
  dialogues?: any[];
  dialogue?: number;
  onCancel?: () => void;
  language?: any;
  changeLanguage?: (language: any) => void;
  onSave?: () => void;
  currentProject?:any;
};

interface IVariableState {
  toRemove: {options: any[], responses: any[]};
  curId: {options: number, responses: number};
  variable: {id?: number, name?: string, ans_key?: string, entities?: string};
  options: any[];
  responses: any[];
  responseModal: boolean;
  currentResponse?: number;
  variableModal? : boolean;
  match?:any;
  currentProject?:any;
  optionModal?:any;
  currentOption?:any;
  response?:any;
};

class Variable extends React.Component<IVariableProps, IVariableState> {

  state:IVariableState = {
    toRemove: {options: [], responses: []},
    curId: {options: 0, responses: 0},
    variable: {name: undefined, ans_key: undefined, entities: undefined},
    options: [],
    responses: [],
    responseModal: false,
    variableModal: false,
  };

  cancelVariableModal = () => {
    this.setState({ variableModal: false });
  }

  /// do it in the option and the responses
  saveIfRequired = (option?) => {
    if (!this.state.variable.id) {
      this.ok();
    } else if (option) {
      this.save();
    }
  }
//

  getVariable = () => {
    let {variable, dialogue} = this.props;
    API.getDialogueVariable(dialogue, variable).then((r: any) => {
      let variable = r.data.variable;
      variable.entities = variable.wit_entities && variable.wit_entities.join(',');
      let curId = 0;
      let options = r.data.options.map(e => {
        e.index = curId++;
        return e;
      });
      curId = 0;
      let responses = r.data.responses.map(e => {e.index = curId++; return e});
      console.log(responses)
      this.setState({variable, responses, options,
        curId: {options: r.data.options.length, responses: r.data.responses.length},
        toRemove: {options: [], responses: []} });
        /// edited
        //this.cancelVariableModal()
    });
  }

  componentDidUpdate(prevProps) {
    let {visible, variable} = this.props;
    if (visible && !prevProps.visible) { // it became visible
      if (variable) {
        this.getVariable();
      } else {
        this.setState({toRemove: {options: [], responses: []}, variable: {},
            curId: {options: 0, responses: 0}, options: [], responses: []});
      }
    }
  }

  addRow = (key) => {
    let x: any = {};
    x[key] = this.state[key];
    x.curId = this.state.curId;
    x[key].push({ index: this.state.curId[key] });
    x.curId[key]++;
    this.setState(x);
  }
  removeRow = (key, rowId) => {
    let x: any = {};
    x[key] = this.state[key];
    x.toRemove = this.state.toRemove;
    var index = -1;
    for (var i = 0; i < x[key].length; i++) {
      if (x[key][i].index === rowId) {
        index = i;
        if (x[key][i].id){
          x.toRemove[key].push(x[key][i]);
        }
        break;
      }
    }
    x[key].splice(index, 1);
    this.setState(x);
  }

  save = () => {
    let {dialogue} = this.props;
    let promises: AxiosPromise<any>[] = [];
    Object.keys(this.state.toRemove).forEach(key => {
      this.state.toRemove[key].forEach(element => {
        promises.push(API.delete(key, element, dialogue, this.state.variable.id));
      });
    });

    promises.push(API.editVariable(dialogue, this.state.variable));

    this.state.options.forEach(option => {
      if (option.id){
        promises.push(API.editOption(dialogue, option, this.state.variable.id));
      } else {
        promises.push(API.addOption(dialogue, option, this.state.variable.id).then(r => {
          option.id = r.data.id;
          option.response = r.data.response;
          option.response.content_type = 'text';
          option.response.content = option.addedResponse;
          option.addedResponse = null;
          this.setState({options: this.state.options});
          return r;
        }));
      }
    })

    this.state.responses.forEach(response => {
      if (!response.id) {
        promises.push(API.addResponse(dialogue, this.state.variable.id).then( r => {
          API.addResponseContent(dialogue, r.data.id, {content_type: 'text', content: response.addedResponse}, this.state.variable.id);
          response.id = r.data.id;
          response.content_type = 'text';
          response.content = response.addedResponse;
          response.addedResponse = null;
          this.setState({responses: this.state.responses});
          return r;
        }))
      }
    });

    this.setState({toRemove: {options: [], responses: []}});

    axios.all(promises).then(this.props.onSave);
  }

  ok = () => {
    let { dialogue } = this.props;
    if (!this.state.variable.id) {
      API.addVariable(dialogue,this.props.currentProject, this.state.variable).then((r: any) => {
        this.state.variable.id = r.data.id;
        this.setState({variable: this.state.variable});
        this.save();
      })
    } else {
      this.save();
    }
  }

  openResponseModal = (response?) => {
    this.setState({ responseModal: true, currentResponse: response });
  }

  openOptionModal = (option) => {
    this.setState({ responseModal: true, currentResponse: option.response.id, currentOption: option.id });
  }

  cancelResponseModal = () => {
    this.setState({ responseModal: false });
  }

  submitForm = (e: any) => {
    e.preventDefault();
    this.addRow('options')

  }
  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal
        visible={visible}
        closable={false}
        title={
          <div>
            Variable
            <Radio.Group value={this.props.language} onChange={this.props.changeLanguage} style={{position: 'absolute', right: 12, top: 12}}>
              <Radio.Button value='en'>English</Radio.Button>
              <Radio.Button value='sv'>Swedish</Radio.Button>
            </Radio.Group>
          </div>
        }
        okText='Save'
        onCancel={onCancel}
        onOk={this.ok}
      >
      <Row>
        <Col xs={24} sm={8}>Name</Col>
        <Col xs={24} sm={16}>
          <Input value={this.state.variable.name} onChange={(e) => {
            this.state.variable.name = e.target.value;
            this.setState({variable: this.state.variable}); }} />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={8}>key (identifier)</Col>
        <Col xs={24} sm={16}>
          <Input value={this.state.variable.ans_key} onChange={(e) => {
            this.state.variable.ans_key = e.target.value;
            this.setState({variable: this.state.variable}); }} />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={8}>wit entity key(s)</Col>
        <Col xs={24} sm={16}>
          <Input value={this.state.variable.entities} onChange={(e) => {
            this.state.variable.entities = e.target.value;
            this.setState({variable: this.state.variable}); }} placeholder='separated by , if more than one' />
        </Col>
      </Row>
      <div>
          <Divider>Bot says</Divider>
          {this.state.responses.map((response, index) => (
            <div key={'response' + index} style={{ paddingBottom: 12}}>
            {!response.content || response == undefined ? <Input style={{width: '50%'}} placeholder='Text'
                value={response.addedResponse && response.addedResponse[this.props.language]}
                  onChange={(e) => {
                    if (!this.state.responses[index].addedResponse) this.state.responses[index].addedResponse = {};
                    this.state.responses[index].addedResponse[this.props.language] = e.target.value;
                    this.setState({responses: this.state.responses}); }} /> :

              <a onClick={() => this.openResponseModal()}> {response.content_type === 'text' ? (response.content[this.props.language] ?
                response.content[this.props.language].substr(0, 50) : '') +
              (!response.content[this.props.language] || response.content[this.props.language].length > 50 ? ' ...' : '') :
              response.content_type} </a>}

              <Icon style={{ float: 'right', marginTop: -4 }}
                className='dynamic-delete-button'
                type='minus-circle-o'
                onClick={() => this.removeRow('responses', response.index)}
              />
            </div>
          ))}
          <Row type='flex' justify='center'><Col span={20}>
            <Button type='dashed' onClick={() => this.addRow("responses")} style={{ width: '100%', marginBottom: 15 }}>
              <Icon type='plus' /> Add response
            </Button>
          </Col></Row>
        </div>
<div>

<Divider>Options</Divider>
  <Form  onSubmit={this.submitForm} >
{this.state.options.map((option, index) => (
  <div key={'option' + index} style={{ paddingBottom: 12}}>

    {!option.response || option.response.content_type == undefined ? <Input style={{width: '44%'}} placeholder='Text'
        value={option.addedResponse && option.addedResponse[this.props.language]}
          onChange={(e) => {
             if (!this.state.options[index].addedResponse) this.state.options[index].addedResponse = {};
            this.state.options[index].addedResponse[this.props.language] = e.target.value;
            this.setState({options: this.state.options}); }} />
    : <a style={{width: '44%', paddingTop: 15}} onClick={() => this.openOptionModal(option)}> {option.response.content_type === 'text' ? (option.response.content[this.props.language] ?
      option.response.content[this.props.language].substr(0, 50) : '') +
    (!option.response.content[this.props.language] || option.response.content[this.props.language].length > 50 ? ' ...' : '') :
    option.response.content_type} </a>
    }
    <Input style={{width: '44%'}} placeholder='value' value={option.value}
      onChange={(e) => {
        this.state.options[index].value = e.target.value;
        this.setState({options: this.state.options}); }} />

    <Icon style={{ float: 'right', marginTop: -4 }}
      className='dynamic-delete-button'
      type='minus-circle-o'
      onClick={() => this.removeRow('options', option.index)}
    />
  </div>
))}
<Row type='flex' justify='center'><Col span={20}>
  <Button type='dashed' onClick={() => this.addRow('options')} style={{ width: '100%', marginBottom: 15 }}>
    <Icon type='plus' /> Add options
  </Button>
</Col></Row>
 <Button htmlType='submit' style={{opacity:0, width: '00%' ,top:"00%"}}  ></Button>
</Form>
</div>
          <Response
            visible={this.state.responseModal}
            response={this.state.currentResponse}
            option={this.state.currentOption}
            variable={this.props.variable}
            onSave={this.getVariable}
            onCancel={this.cancelResponseModal}
          />
          {console.log(this.state.options,this.props.variable,this.state.responses)}
      </Modal>
    );
  }
}


function mapStateToProps(state: any) {
  return {
    dialogue: state.bot.selectedDialogue,
    language: state.language.value,
    currentProject: state.bot.currentProject,
    dialogues: state.bot.dialogues,

  };
}

const mapDispatchToProps = {
  changeLanguage
};

export default connect<any>(mapStateToProps, mapDispatchToProps)(Variable);
