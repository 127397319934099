import * as React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Router, Route, Switch} from 'react-router';
import { Redirect} from 'react-router-dom';

// import { createBrowserHistory } from 'history';
// import Header from '../components/Header';
// import PageHeader from '../components/Header';
import { generateRequireSignInWrapper } from 'redux-token-auth';
import projectForm from '../components/Project/CreateProject_Form';
import Bot from '../components/Project/Bot';
// import Account from '../components/Project/Account';
import AddUser from '../components/AddUser' ;
import UsersList from '../components/UsersList';
import { Layout } from 'antd';
// import HomeHeader from "../components/HomeHeader";
import ProjectMenu from "../components/ProjectMenu";
// import PageHeader2 from "../components/Header2";
import  accountHome from"../components/Project/AccountHome";
import UserChat from "../components/UserChat";
import UsersData from "../components/UsersData"
import WitAi from '../components/witA'
import {includes} from 'lodash'
const { Content, Footer } = Layout;

// const history = createBrowserHistory({});
const requireSignIn: (a:any) => any = generateRequireSignInWrapper({
  redirectPathIfNotSignedIn: '/signin',    // edited from '/signin'
});

class ProjectApp extends React.Component<{isSignedIn: boolean,   currentProject?: number, history:any}> {
  render() {

    console.log('location2', this.props.history.location.pathname)
    
    return (  
      <Router history={this.props.history}>  
        <Layout >
          {!includes(['/signin','/signup','/account','/', '/newProject'], this.props.history.location.pathname) && this.props.isSignedIn && <ProjectMenu/>}
          <Content className='proj-page' >
            <div style={{height: '100%'}}>
              <Route exact path='/project/:id?/bot' component={requireSignIn(Bot)} />
              <Route exact path="/project/:id?/edit" component={requireSignIn(projectForm)} />
              <Route exact path="/project/:id?/adduser" component={requireSignIn(AddUser)} />
              <Route exact path="/project/:id?/witai" component={requireSignIn(WitAi)} />
              <Route exact path='/project/:id?/users' component={requireSignIn(UsersList)} />
              <Route exact path="/project/:id?/accountHome" component={requireSignIn(accountHome)} />
              <Route exact path="/project/:id?/userChat"  component={requireSignIn(UserChat)} />
              <Route exact path="/project/:id?/usersData"  component={requireSignIn(UsersData)} />
            </div>
          </Content>
        </Layout>
    </Router>
      );
    }
  }

  function mapStateToProps(state: any) {
    return {
      isSignedIn: state.reduxTokenAuth.currentUser.isSignedIn,
      currentProject: state.bot.currentProject,
    };
  }

  export default connect(mapStateToProps)(ProjectApp);
