import * as types from '../constants/ActionTypes';
import {assign} from '../assign';

const initialState = [];


export default function usersReducer(state: any = initialState, action: any) {
  switch (action.type){
    case types.SET_USERS:
      return [ ...action.users];
    default:
      return state;
  }
}
