import { generateAuthActions } from 'redux-token-auth';
import { api_url } from './api/env';

const config = {
  storage: {},
  authUrl: api_url + '/auth',
  userAttributes: {
    name: 'name',
    image: 'image',
    id: 'id',
    email: 'email'
  },
  userRegistrationAttributes: {
    name: 'name'
  },
};

const {
  registerUser,
  signInUser,
  signOutUser,
  verifyCredentials,
} = generateAuthActions(config);

export {
  registerUser,
  signInUser,
  signOutUser,
  verifyCredentials,
}
