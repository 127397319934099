
export default class Utils{
    static parseQueryString= (qs) => {
        const items = qs.split('&');
        return items.reduce((data, item) => {
          let [key, value] = item.split('=');
          key = key.replace('?','')
          if(data[key] !== undefined) {
            if(!Array.isArray(data[key])) {
              data[key] = [ data[key] ]
            }       
            data[key].push(value)
          }
          else {
            data[key] = value
          }
          return data
        }, {})
      }
}