// import {CHANGE_CONTEXT, SET_DIALOGUES} from '../constants/ActionTypes';
import * as types from '../constants/ActionTypes';
import {assign} from '../assign';
import { Mode } from '../constants/types';

const initialState = {selectedContext: undefined, selectedArc: undefined, selectedDialogue: undefined, mode: Mode.edit, parents_ids:[]};

export default function botReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case types.CHANGE_CONTEXT:
      return assign({}, state, {selectedContext: action.context} );
    // case types.SET_PARENTS:
    //   return assign({}, state, {parents_ids: action.parents_ids} );
    case types.SET_DIALOGUES:
      let ind = action.dialogues.findIndex(e => e.id === -1);
      if (ind !== -1) { action.dialogues.splice(ind, 1); }
      return assign({}, state, {dialogues: action.dialogues} );
    case types.SET_SELECTED_DIALOGUE:
      return assign({}, state, {selectedDialogue: action.dialogue, selectedArc: undefined} );
    case types.SET_SELECTED_ARC:
      return assign({}, state, {selectedDialogue: undefined, selectedArc: action.arc} );
    case types.CHANGE_MODE:
      return assign({}, state, {mode: action.mode} );
    case types.SET_SELECTED_PROJECT:
      return assign({}, state, {currentProject: action.project} );
    default:
      return state;
  }
}
