import {combineReducers} from 'redux';
import { reduxTokenAuthReducer } from 'redux-token-auth';
import language from './language';
import bot from './bot';
import projects from "./projects";
import users from "./users";
import witAi from "./wit_reducer"
const rootReducer = combineReducers({
  language,
  reduxTokenAuth: reduxTokenAuthReducer,
  bot,
  projects,
  users,
  witAi
});

export default rootReducer;
