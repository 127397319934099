import { api_url } from './env';
import axios from 'axios';

export class API {
    static sendMessage(projectId, text, language = 'en') {
      return axios.post(api_url + '/projects/' + projectId + `/chatbot`, {
        text,
        language,
        project_id: projectId,
        debug_mode: true
      });
    }

    static getMessages() {
      // todo
    }

    static release(projectId ){
      return axios.get(api_url + `/projects/${projectId}/release` )
    }

    ////       
    /// add a project
    static getProject(userId,projectId ){
    return axios.get(api_url + `/users/${userId}/projects/${projectId}/show` )
  }

    static createProject(userId, projectData){
      return axios.post(api_url + `/users/${userId}/projects/create`, projectData)
    }
    ///
    static updateProject(userId, projectId ,projectData){
    return axios.put(api_url + `/users/${userId}/projects/${projectId}/update` ,projectData)
  }

    static deleteProject(userId, projectId){
      return axios.delete(api_url + `/users/${userId}/projects/${projectId}/destroy`)
    }

    static getProjects(userId) {
      return axios.get(api_url + `/users/${userId}/projects/list`)
    }
     /// users APIs
     static getUsers(userId,projectId){
     return axios.get(api_url + `/users/${userId}/projects/${projectId}/list_project_users`)
   }

   static addUser(userId, projectId ,userData){
     return axios.post(api_url + `/users/${userId}/projects/${projectId}/add_users_to_project`, userData)
   }

   static removeUser(userId,projectId ,userEmail ){
     return axios.post(api_url +  `/users/${userId}/projects/${projectId}/remove_users_from_project`, {emails:[userEmail]})
   }
     ///

    static listContexts(projectId) {
      return axios.get(api_url + '/contexts/list?project_id=' + projectId).then((r) => {
        r.data.splice(0, 0, {id: null, name: 'Context-Free', wit_intent: null});
        return r.data;
      });
    }

    static addContext(projectId,context) {
      return axios.post(api_url + '/contexts', {project_id: projectId, name: context.name});
    }

    static deleteContext(projectId,contextId) {
      return axios.delete(api_url + `/contexts/${contextId}`);
    }

    static listDialogues(context, projectId) {
      return axios.post(api_url + '/dialogues/list', {context_id: context, project_id: projectId}).then(r => {
        return r.data.map(d => {
          return {id: d.dialogue.id, intent: d.dialogue.intent, name: d.dialogue.name, parents_id: Object.keys(d.arcs).length === 0 ? null : Object.keys(d.arcs).map(arc => {
            return {id: d.arcs[arc].parent_id};
          }), conditions: Object.keys(d.arcs).map(arc => d.arcs[arc].conditions),
              arcs: Object.keys(d.arcs),
              arcsRaw: d.arcs
          }; });
        });
      }

      static getDialogue(id) {
        return axios.get(api_url + `/dialogues/${id}`, {headers: {'Cache-Control': 'no-cache'}} );
      }

      static getVariables(arcId){
        return axios.get(api_url + `/arcs/${arcId}/list_variables`)
      }

      static getVariable(variableId , dialogueId?){
        return axios.get(api_url + (dialogueId ? `/dialogues/${dialogueId}` : '') + `/variables/${variableId}/show`)
      }
      static getArc(arcId) {
        return axios.get(api_url + `/arcs/${arcId}`, {headers: {'Cache-Control': 'no-cache'}});
      }

      static getAvailableOptions(dialogueId) {
        return axios.get(api_url + `/dialogues/${dialogueId}/options/list`, {headers: {'Cache-Control': 'no-cache'}});
      }

      static deleteDialogue(dialogueId) {
        return axios.delete(api_url + `/dialogues/${dialogueId}`);
      }

      static delete(key, object, dialogueId, variableId?) {
        if (variableId) {
          return axios.delete(api_url + `/dialogues/${dialogueId}/variables/${variableId}/${key}/${object.id}/destroy`);
        }
        return axios.delete(api_url + `/dialogues/${dialogueId}/${key}/${object.id}/destroy`);
      }

      static getDialogueResponse(dialogueId, responseId, optionId) {
        return axios.get(api_url + `/dialogues/${dialogueId}${optionId ? '/options/' + optionId : ''}/responses/${responseId}/show`, {headers: {'Cache-Control': 'no-cache'}});
      }

      static getVariableResponse(dialogueId, variableId, responseId, optionId) {
        return axios.get(api_url + `/dialogues/${dialogueId}/variables/${variableId}${optionId ? '/options/' + optionId : ''}/responses/${responseId}/show`, {headers: {'Cache-Control': 'no-cache'}});
      }

      static getDialogueVariable(dialogueId, variableId) {
        return axios.get(api_url + `/dialogues/${dialogueId}/variables/${variableId}`, {headers: {'Cache-Control': 'no-cache'}});
      }

      static addDialogue(dialogue, projectId) {
        dialogue.project_id = projectId;
        return axios.post(api_url + '/dialogues', dialogue).then((r) => {
          let d = r.data;
          return {id: d.dialogue.id, name: d.dialogue.name, parents_id: Object.keys(d.arcs).length === 0 ? null : Object.keys(d.arcs).map(arc => {
            return {id: d.arcs[arc].parent_id}
          }), conditions: Object.keys(d.arcs).map(arc => d.arcs[arc].conditions),
              arcs: Object.keys(d.arcs),
              arcsRaw: d.arcs
          };
        });
      }

      static editDialogue(dialogue) {
        return axios.put(api_url + '/dialogues/' + dialogue.id + '/update', dialogue);
      }

      static addParentRoot(dialogueId) {
        return axios.post(api_url + '/dialogues/' + dialogueId + '/add_root_parent');
      }

      static addParameter(dialogueSeqId, parameter) {
        return axios.post(api_url + `/arcs/${dialogueSeqId}/parameters`, parameter);
      }

      static editParameter(dialogueSeqId, parameter) {
        return axios.put(api_url + `/arcs/${dialogueSeqId}/parameters/${parameter.id}/update`, parameter)
      }

      static deleteParameter(dialogueSeqId, parameterId) {
        return axios.delete(api_url + `/arcs/${dialogueSeqId}/parameters/${parameterId}/destroy`);
      }

      static addSequenceOption(dialogueSeqId, optionId, conditionId) {
        return axios.post(api_url + `/arcs/${dialogueSeqId}/options/${optionId}/add_to_condition`, {condition_id: conditionId});
      }

      static deleteSequenceOption(optionId, conditionId) {
        return axios.delete(api_url + `/conditions/${conditionId}/options/${optionId}/remove_from_condition`);
      }

      static deleteOption(id,dialogueId,variableId){
        return axios.delete(api_url + `/dialogues/${dialogueId}/variables/${variableId}/option/${id}`)
      }

      static addOption(dialogueId, option, variableId) {
        return axios.post(api_url + `/dialogues/${dialogueId}/variables/${variableId}/options/create`, option).then(r => {
          if (Object.keys(option.addedResponse).length > 0 ) {
            this.addResponseContent(dialogueId, r.data.response.id, {content_type: 'text', content: option.addedResponse}, variableId, r.data.id)
          }
          return r;
        });
      }

      static editOption(dialogueId, option, variableId) {
        return axios.put(api_url + `/dialogues/${dialogueId}/variables/${variableId}/options/${option.id}/update`, option);
      }

      static addResponse(dialogueId, variableId?, optionId?) {
        if (variableId) {
          return axios.post(api_url + `/dialogues/${dialogueId}/variables/${variableId}${optionId ? '/options/' + optionId : ''}/responses/create`, {});
        }
        return axios.post(api_url + `/dialogues/${dialogueId}${optionId ? '/options/' + optionId : ''}/responses/create`, {});
      }

      static addResponseContent(dialogueId, responseId, reponseContent, variableId?, optionId?) {
        if (variableId) {
          return axios.post(api_url + `/dialogues/${dialogueId}/variables/${variableId}${optionId ? '/options/' + optionId : ''}/responses/${responseId}/response_contents/create`, reponseContent);
        }
        return axios.post(api_url + `/dialogues/${dialogueId}${optionId ? '/options/' + optionId : ''}/responses/${responseId}/response_contents/create`, reponseContent);
      }
      static editResponseContent(dialogueId, responseId, reponseContent, variableId?, optionId?) {
        if (variableId) {
          return axios.put(api_url + `/dialogues/${dialogueId}/variables/${variableId}${optionId ? '/options/' + optionId : ''}/responses/${responseId}/response_contents/${reponseContent.id}/update`, reponseContent);
        }
        return axios.put(api_url + `/dialogues/${dialogueId}${optionId ? '/options/' + optionId : ''}/responses/${responseId}/response_contents/${reponseContent.id}/update`, reponseContent);
      }

      static deleteResponseContent(dialogueId, responseId, reponseContentId, variableId?, optionId?) {
        if (variableId) {
          return axios.delete(api_url + `/dialogues/${dialogueId}/variables/${variableId}${optionId ? '/options/' + optionId : ''}/responses/${responseId}/response_contents/${reponseContentId}/destroy`);
        }
        return axios.delete(api_url + `/dialogues/${dialogueId}${optionId ? '/options/' + optionId : ''}/responses/${responseId}/response_contents/${reponseContentId}/destroy`);
      }
      static addVariable(dialogueId, projectId, variable) {
        if (variable.entities){
          variable.wit_entities = variable.entities.split(',');
        }
        variable.project_id = projectId;
        variable.dialogue_id = dialogueId;
        return axios.post(api_url + `/dialogues/${dialogueId}/variables`, variable);
      }
      static editVariable(dialogueId, variable) {
        if (variable.entities) {
          variable.wit_entities = variable.entities.split(',');
        }
        return axios.put(api_url + `/dialogues/${dialogueId}/variables/${variable.id}/update`, variable);
      }
      static getUserChat(projectId,userEmail){
      return axios.post(api_url + `/projects/${projectId}/get_chat_messages`, {email:userEmail, debug_mode:true})
      }

      static getUsersData(projectId,userId){
        return axios.post(api_url + `/projects/${projectId}/user_data/list`,{user_id: userId})
      }
      static uploadNlpFile(projectId,file){
        console.log('api file',file)
          return axios.post(api_url +  `/projects/${projectId}/train_wit`,
          file,
          {headers: { 'Content-Type': 'multipart/form-data' }}
      )}
      static uploadDslFile(projectId,file){
        return axios.post(api_url + `/projects/${projectId}/import_context_dialogues_data`,
        file,
        {headers: { 'Content-Type': 'multipart/form-data' }}
      )}
      static ForgotPassword(data){
        return axios.post(api_url + `/auth/password`,data)
      }
      static resetPassword(data, headers){
        return axios.put(api_url + `/auth/password`,
          data,
          {headers: headers}
        )
      }
      static setFacebookAccessToken( userId, projId, data){
        return axios.post(api_url + `/users/${userId}/projects/${projId}/set_facebook_access_token`,data)
      }


  }
