import * as React from 'react';
import { connect } from 'react-redux';
import { API } from '../api/api';

interface UserChatProps {
  match?: any;
  user?: any;
}

class UserChat extends React.Component<UserChatProps>{
  state = {
    messages: [],
  }

  componentDidMount() {
    API.getUserChat(this.props.match.params.id, this.props.user.attributes.email).then(r => {
      console.log(r.data)
      this.setState({ messages: r.data })
    });
  }

  renderMesseges() {
    return <div className="msger-chat" style={{ border: 0, minHeight: 400, maxHeight: 700, overflowX: 'hidden', overflowY: 'auto', }}>
      {this.state.messages.map((message:any, index) => {
        return <div className="" key={index} style={{ padding: 10 }}>
          {!message.is_user_message ?
            <div className="msg left-msg">
              <img alt=""
                className="msg-img"
                src="assets/images/optobot_icon.png"
              />
              <div className="msg-bubble">
                <div className="msg-text">
                  {<span style={{ wordBreak: 'break-word' }}>{message.message}</span>}
                </div>
              </div>
            </div>
            :
            <div className="msg right-msg">
              <img alt=""
                className="msg-img"
                src="assets/images/user_icon.png"
              />

              <div className="msg-bubble">
                <div className="msg-text">
                  {<span style={{ wordBreak: 'break-word' }}>{message.message}</span>}

                </div>
              </div>
            </div>
          }
        </div>

      })}
    </div>

  }

  render() {
    return (
      <div>
        <h1> Chat History</h1>
        {this.renderMesseges()}
      </div>
    )

  }
}

function mapStateToProps(state: any) {
  return { user: state.reduxTokenAuth.currentUser };
}

export default connect<any>(mapStateToProps)(UserChat);
