import * as types from '../constants/ActionTypes';
import { API } from '../api/api';
import { Mode } from '../constants/types';
import * as _ from 'lodash';

export function changeLanguage(item: any) {
  return { type: types.CHANGE_LANGUAGE, language: item.key || item.target.value };
}

export function setDialogues(dialogues: any) {
  return { type: types.SET_DIALOGUES, dialogues };
}

export function setSelectedDialogue(dialogue: any) {
  return { type: types.SET_SELECTED_DIALOGUE, dialogue };
}

export function setSelectedArc(arc: any) {
  return { type: types.SET_SELECTED_ARC, arc };
}

export function setProjectId(projectId: any) {
  return { type: types.SET_PROJECT_ID, projectId };
  console.log(projectId)
}

export function setMode(mode: Mode) {
  return { type: types.CHANGE_MODE, mode };
}

export function setCurrentProject(project?: number) {
  return { type: types.SET_SELECTED_PROJECT, project }
}

export function setParents(parents_id: number) {
  return { type: types.SET_PARENTS, parents_id }
}
/// edited {selectedDialogue}
export function addParent(parentId: number) {
  return (dispatch, getState) => {
    let { dialogues, selectedDialogue } = getState().bot;
    console.log(selectedDialogue)
    let newDialogues = _.cloneDeep(dialogues);
    let selectedIndex = newDialogues.findIndex(e => e.id === selectedDialogue);
    let currentDialogue = newDialogues[selectedIndex];
    currentDialogue.added_parents = [parentId];
    API.editDialogue(currentDialogue).then(_r => {
      newDialogues[selectedIndex].push({ id: parentId });
      console.log(parentId)
      dispatch(setDialogues(newDialogues));
      dispatch(setMode(Mode.edit));
    });
  };
}

export function addDialogue(parentId: number) {
  return (dispatch, getState) => {
    let { dialogues, selectedContext, currentProject } = getState().bot;
    let newDialogues = _.clone(dialogues);
    API.addDialogue({ context_id: selectedContext, name: 'New dialogue', parents_ids: [parentId] }, currentProject).then(d => {
      newDialogues.push(d);
      dispatch(setDialogues(newDialogues));
    });
  };
}

export function setProjects(projects) {
  return { type: types.SET_PROJECTS, projects };
}

export function setUsers(users) {
  return { type: types.SET_USERS, users };
}

/// users
export function addUser(projectId, values) {
  return (dispatch, getState) => {
    let userId = getState().reduxTokenAuth.currentUser.attributes.id;
    API.addUser(userId, projectId, values).then(r => {
      console.log('yesss');
      API.getUsers(userId, projectId).then(r => {
        dispatch(setUsers(r.data));
      });
    })
  }
}

export function removeUser(userId, projectId, user_emails) {
  return (dispatch, getState) => {
    let userId = getState().reduxTokenAuth.currentUser.attributes.id;
    let users = getState().users;
    API.removeUser(userId, projectId, user_emails).then(r => {
      let ind = users.findIndex(e => e.email === user_emails);
      users.splice(ind, 1);
      dispatch(setUsers(users));
    });
  };
}

export function updateProject(projectId, values) {
  // debugger
  return (dispatch, getState) => {
    let userId = getState().reduxTokenAuth.currentUser.attributes.id;
    let projects = getState().projects;
    let projectIndex = projects.findIndex(r => r.id == projectId)
    console.log(values)
    API.updateProject(userId, projectId, values).then(r => {
      projects[projectIndex].name = values.name;
      projects[projectIndex].nlp_engine = values.nlp_engine;
      projects[projectIndex].facebook_page_access_token = values.facebook_page_access_token;
      projects[projectIndex].fallback_setting = values.fallback_setting;
      projects[projectIndex].isPrivate = values.isPrivate
      dispatch(setProjects(projects))
    })
  }
}

export function createProject(values, callback) {
  return (dispatch, getState) => {
    let projects = getState().projects;
    let userId = getState().reduxTokenAuth.currentUser.attributes.id;
    console.log(values);
    API.createProject(userId, values).then(r => {
      projects.push(r.data);
      dispatch(setProjects(projects));
      dispatch(setCurrentProject(r.data.id));
      callback(r.data.id)
    })
  }
}
///
export function deleteProject(projectId) {
  return (dispatch, getState) => {
    let userId = getState().reduxTokenAuth.currentUser.attributes.id;
    let projects = getState().projects;
    API.deleteProject(userId, projectId).then(r => {
      dispatch(setCurrentProject(undefined));
      let ind = projects.findIndex(e => e.id === projectId);
      projects.splice(ind, 1);
      dispatch(setProjects(projects));
    });
  };
}

///
export function deleteDialogue() {
  return (dispatch, getState) => {
    let { dialogues, selectedDialogue } = getState().bot;
    let newDialogues = _.clone(dialogues);
    API.deleteDialogue(selectedDialogue).then(r => {
      dispatch(setSelectedDialogue(undefined));
      let ind = newDialogues.findIndex(e => e.id === selectedDialogue);
      newDialogues.splice(ind, 1);

      dispatch(setDialogues(newDialogues));
    });
  };
}

export function changeContext(context: number) {
  return (dispatch, getState) => {
    if (getState().bot.selectedContext !== context) {
      dispatch({ type: types.CHANGE_CONTEXT, context });
      let { currentProject } = getState().bot;
      API.listDialogues(context, currentProject).then(r => {
        dispatch(setDialogues(r));
      });
    }
  };
}

export function deleteArc() {
  return (dispatch, getState) => {
    let { dialogues, selectedArc } = getState().bot;
    let selectedIndex = dialogues.findIndex(e => e.id === selectedArc.child);
    let currentDialogue = _.clone(dialogues[selectedIndex]);
    currentDialogue.removed_parents = [selectedArc.parent];
    API.editDialogue(currentDialogue).then(r => {
      dispatch(setSelectedArc(undefined));
      let parentIndex = dialogues[selectedIndex].parents_id.findIndex(e => selectedArc.parent);
      dialogues[selectedIndex].parents_id.splice(parentIndex, 1);
      dispatch(setDialogues(dialogues));
    });
  };
}

export function changeContextOnMessage(context: number, dialogueId?: number) {
  return (dispatch, getState) => {
    if (context == null) { return; }
    if (getState().bot.selectedContext !== context) {
      dispatch({ type: types.CHANGE_CONTEXT, context });
      let { currentProject } = getState().bot;
      API.listDialogues(context, currentProject).then(r => {
        dispatch(setDialogues(r));
      });
    } else {
      if (dialogueId) {
        dispatch(setSelectedDialogue(dialogueId));
      }
    }
  };
}
export function createApp(values?: any, language?: any) {
  console.log(values)
  const url = 'https://api.wit.ai/apps?v=20170307';
  const token = values.NLP;
  const auth = 'Bearer ' + token;
  if (language == 'sv') {
    let name = (values.name + '-sv').split(' ').join('-');
    const request = fetch(url, {
      method: 'POST',
      headers: { Authorization: auth },
      body: JSON.stringify({
        name: name,
        lang: language,
        private: false
      })
    })
    return (dispatch) => {
      request.then(res => res.json())
        .then(data => {
          console.log("data");
          dispatch({ type: types.SV_WIT_APP, data })
        })
    }
  }
  else if (language == 'en') {
    let name = (values.name + '-en').split(' ').join('-');
    const request = fetch(url, {
      method: 'POST',
      headers: { Authorization: auth },
      body: JSON.stringify({
        name: name,
        lang: language,
        private: false
      })
    })
    return (dispatch) => {
      request.then(res => res.json())
        .then(data => {
          console.log("data")

          dispatch({ type: types.EN_WIT_APP, data })
        })
    }
  }
}
// export function getAppName(id?:any,access_token?:any){
//  const url = `https://api.wit.ai/apps/${id}`;
//  const auth =`Bearer ${access_token}`;
//       const request = fetch(url, {
//         method:'GET',
//         headers:{Authorization:auth},
//       });
//       return(dispatch)=>{
//   debugger

//         request.then(res=>res.json())
//         .then(data=>{console.log(data)
//         dispatch({type:'dfdf',data})
//         })
//       }
// }
export function saveNlp(nlp) {
  return { type: types.NLP, nlp }
}

export function createWitApp(values: any) {
  const CLIENT_TOKEN = 'XZJDUCXFOL6HCHD5Z5VRR3QMII2LONJG';
  const auth = 'Bearer ' + CLIENT_TOKEN;
  let name = (values.name).split(' ').join('-');
  const request = fetch('https://api.wit.ai/apps?v=20170307', {
    method: 'POST',
    headers: { Authorization: auth },
    body: JSON.stringify({
      name: name,
      lang: values.lang,
      private: values.private,
      desc: values.appDesc
    })
  });
  return (dispatch) => {
    request.then(res => res.json())
      .then(data => {
        console.log(data)
        dispatch({ type: types.WIT_APP, data })
      })
  }
}
//////get entity values/////////
export function getEntityValues(entity_name?: any, access_token?: any) {
  const uri = `https://api.wit.ai/entities/${entity_name}?v=20170307`;
  const auth = `Bearer ${access_token}`;
  const request = fetch(uri, {
    method: 'GET',
    headers: { Authorization: auth },
  });
  return (dispatch) => {
    request.then(res => res.json())
      .then(data => {
        console.log(data)
        dispatch({ type: types.WIT_ENTITY_VALUES, data })
      })
  }
}
export function createWitSample(message?: any, values?: any, access_token?: any) {
  const uri = 'https://api.wit.ai/samples?v=20170307';
  // debugger
  const auth = `Bearer ${access_token}`;
  console.log(values)
  // debugger
  let entities = values.map(v => {

    return {
      "entity": v.key, "value": v.value, "start": v.start,
      "end": v.end
    }
  });
  console.log(entities);
  const request = fetch(uri, {
    method: 'POST',
    headers: { Authorization: auth },
    body: JSON.stringify([{
      "text": message,
      "entities": entities
    }])
  });
  return (dispatch) => {
    request.then(response => response.json())
      .then(responseJson => {
        console.log(responseJson)
        dispatch({ type: types.WIT_MESSAGE, payload: responseJson })
      });
  }
}

export function sendMsgToWit(message: string, client_token: string) {
  const q = encodeURIComponent(message);
  // https://api.wit.ai/message?q=12%20kg%20and%2010%20cm&explain=false&junk=true&verbose=true&autosuggest=true
  const uri = `https://api.wit.ai/message?q=${q}&explain=false&junk=true&verbose=true&autosuggest=true`;
  const auth = `Bearer ${client_token}`;
  const request = fetch(uri, {
    method: 'GET',
    headers: { Authorization: auth }
  });
  return (dispatch) => {
    request.then(response => response.json())
      .then(responseJson => {
        console.log(responseJson)
        dispatch({ type: types.WIT_MESSAGE, payload: responseJson })
      });
  }
}

export function getEntities(access_token: any) {

  const url = 'https://api.wit.ai/entities?v=20170307';
  const auth = `Bearer ${access_token}`;

  const request = fetch(url, {
    method: 'GET',
    headers: { Authorization: auth }
  });
  return (dispatch) => {
    request.then(response => response.json())
      .then(responseJson => {
        console.log(responseJson)
        dispatch({ type: types.WIT_ENTITIES, payload: responseJson })
      });
  }
}


export function postEntity(entity: any, client_token: string) {

  const auth = 'Bearer ' + client_token;
  const request = fetch('https://api.wit.ai/entities?v=20170307', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      Authorization: auth
    },
    body: JSON.stringify({
      "doc": 'user defined',
      "id": entity
    })

  });
  return (dispatch) => {
    // debugger
    // console.log(request);
    request.then(response => response.json())
      .then(responseJson => {
        console.log(responseJson)
        dispatch({ type: types.WIT_POST_ENTITIES, payload: responseJson })
      });
  }
}

/////////////Get All Entities
export function getAllSamples(access_token: any) {

  const url = 'https://api.wit.ai/samples?limit=10';
  const auth = `Bearer ${access_token}`;

  const request = fetch(url, {
    method: 'GET',
    headers: { Authorization: auth }
  });
  return (dispatch) => {
    request.then(response => response.json())
      .then(responseJson => {
        console.log(responseJson)
        dispatch({ type: types.GET_ALL_SAMPLES, payload: responseJson })
      });
  }
}

export function deleteSample(text: any, access_token: any) {
  const url = 'https://api.wit.ai/samples?v=20170307';
  const auth = `Bearer ${access_token}`;
  const request = fetch(url, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      Authorization: auth
    },
    body: JSON.stringify({ "text": text })

  });
  return (dispatch) => {
    // debugger
    // console.log(request);
    request.then(response => response.json())
      .then(responseJson => {
        console.log(responseJson)
        dispatch({ type: "types", payload: responseJson })
      });
  }
}