import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button, Row, Col } from 'antd';
import { signInUser , registerUser } from '../authTokenConfig';
import { Link } from 'react-router-dom';
const FormItem = Form.Item;

function onChange(date, dateString) {
  console.log(date, dateString);
}

interface SignUpProps {
  registerUser: (userData: any) =>  Promise<any>;
  form:any;
  history:any;
}

class SignUp extends React.Component<SignUpProps> {
  state ={
    confirmDirty: false,
  }
submitForm = (e: any) => {
  e.preventDefault();
  this.props.form.validateFields((err, values) => {
    if (!err) {
      const { registerUser } = this.props;
      //console.log(values);
      registerUser({ email: values.email, password: values.password, name:values.name ,passwordConfirmation:values.passwordConfirmation}).then(r => {
        console.log('registerUser');
        this.props.history.push('/');
      }).catch(error => {
        let fields = {}
        for (e in error.response.data.errors){
           if (e != "full_messages"){
             fields[e]={};
             fields[e].value=values[e];
             fields[e].errors=error.response.data.errors[e].map(str => new Error(str))
        }
        }
        this.props.form.setFields(fields);
        console.log( fields );
        console.log( values );
        //this.props.form.setFields({password: {
        //   value: values.password,
        //   errors: error.response.data.errors.password,
        // }})
      });
    }
  });
}
handleConfirmBlur = (e) => {
  const value = e.target.value;
  this.setState({ confirmDirty: this.state.confirmDirty || !!value });
}

compareToFirstPassword = (rule, value, callback) => {
   const form = this.props.form;
   if (value && value !== form.getFieldValue('password')) {
     callback('Two passwords that you enter is inconsistent!');
   } else {
     callback();
   }
 }

validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    else if(value.length < 8){
      callback('Your password must be at least 8 characters long');
    }
    callback();
  }

render(){
  const { getFieldDecorator } = this.props.form;
  return(
    <div className="SignUpPage auth-screen">
      <Row>
          <Col md={10} className='content'>
            <div className='logo'>
                <img src='/assets/images/logo.png'/>
                <h1> Build Bots with ease </h1>
            </div>
            <Form  className='form' layout='vertical' onSubmit={this.submitForm}>
              <FormItem>
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Please input your username!' }],
                  })(
                      <Input prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Username' />
                )}
              </FormItem>
              <FormItem >
                  {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please input your email!' }],
                  })(
                      <Input prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} type='email' placeholder='Email' />
                )}
              </FormItem>

              <FormItem>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input your password!' },{
                          validator: this.validateToNextPassword,
                        }],
              })(
                  <Input prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} type='password' placeholder='Password' />
              )}
              </FormItem>

              <FormItem >
              {getFieldDecorator('passwordConfirmation', {
                rules: [{ required: true, message: 'Please input your password!' },{
                          validator: this.compareToFirstPassword,
                        }],
              })(
                  <Input prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} type='password' placeholder='Rewrite Password' />
                )}
              </FormItem>

              {/* <FormItem >
                <DatePicker onChange={onChange} style={{width:"100%"}} />
              </FormItem> */}

              <FormItem>
              <Button htmlType='submit' className='submit'>
                  Sign Up
              </Button>
              <div className='link'>
                <Link to='/signin'>
                    <span>Already have an account? Signin</span>
                </Link>
              </div>     
              </FormItem>
          </Form>
          </Col>
          <Col md={14}>
            <div className=''>
              <img width='100%' src='/assets/images/homeBg.png'/>
            </div>
          </Col>
      </Row>
</div>
  )
}
}

function mapStateToProps(state: any) {
  return {

  };
}
const WrappedNormalRegisterForm = Form.create()(SignUp);

const mapActionsToProps = { registerUser };

export default connect<any>(mapStateToProps , mapActionsToProps)(WrappedNormalRegisterForm);
