import * as React from 'react';
// import { Link } from 'react-router';
import { Menu, Dropdown, Icon, Button } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { signOutUser } from '../authTokenConfig';
import { changeLanguage ,setCurrentProject } from '../actions';
import { Layout } from 'antd';
import { signInUser } from '../authTokenConfig';
import { withRouter } from 'react-router-dom';
const { Header } = Layout;


interface IHeaderProps {
  changeLanguage?: (language: any) => void;
  signOutUser?: (dispacth: any) => Promise<any>;
  language?: any;
  user?: any;
  setCurrentProject?: (dispatch: any) => void;
  history?:any
};

interface IHeaderState { };

class PageHeader extends React.Component<IHeaderProps, IHeaderState> {
  menu = (
    <Menu onClick={this.props.changeLanguage}>
      <Menu.Item key='en'>English</Menu.Item>
      <Menu.Item key='sv'>Swedish</Menu.Item>
    </Menu>
  );

  render() {
    return (
      <Header className='top-header'>
        <div style={{
          marginRight: '20px', float: 'left'
        }}>
            <Link to="/account" onClick={() => this.props.setCurrentProject!(undefined)}>  
              <img width={200} src='/assets/images/logo.png'/>
            </Link>
        </div>
        <Menu
          // theme='dark'
          mode='horizontal'
          defaultSelectedKeys={['1']}
          style={{ lineHeight: '64px' }}
        >
        {signInUser? console.log("ok"):console.log("no")}
          {/* <Menu.Item key='1'><Link style={{ color: '#D2D9D4' }} to='/account'>My projects</Link></Menu.Item> */}
          <Menu.Item style={{ float: 'right' }} key='logout' onClick={this.props.signOutUser}>Logout</Menu.Item>
          <Menu.Item disabled={true} style={{ cursor: 'default', float: 'right' }} key='email'>{this.props.user && this.props.user.email}</Menu.Item>
          <Menu.Item disabled={true} style={{ cursor: 'default', float: 'right' }} key='language'>
            <Dropdown overlay={this.menu} trigger={['click']}>
              <Button style={{ marginLeft: 8 }}>
                {this.props.language.name} <Icon type='down' />
              </Button>
            </Dropdown>
          </Menu.Item>
          <Menu.Item style={{ float: 'right'}} key='help' >
            <a target='_blank' href="https://docs.optobot.ai/documentation">
              <Icon style={{color: '#D2D9D4', fontSize: 20 }} type="question-circle" />
            </a>
          </Menu.Item>

        </Menu>
      </Header>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    user: state.reduxTokenAuth.currentUser.attributes,
    language: state.language
  };
}

const mapDispatchToProps = {
  signOutUser,
  changeLanguage,
  setCurrentProject
};

export default connect<IHeaderProps>(mapStateToProps, mapDispatchToProps)( withRouter( PageHeader ));
