import * as types from '../constants/ActionTypes';
import {assign} from '../assign';

const initialState = {app:{},en_app:{},sv_app:{},nlp:'',msg:{},entities:[],entity_values:[],AllSamples:[],appName:{}};

// debugger;
export default function witReducer(state: any = initialState, action: any) {

  // console.log("action");
  // console.log(action.payload);
  switch (action.type){
    case types.WIT_MESSAGE:
      console.log(action.payload._text)
      return assign({},state,{msg:action.payload})
    // console.log(action.payload)
    case types.WIT_ENTITIES:
      return assign({},state,{entities:action.payload})

    case types.WIT_POST_ENTITIES:
    // debugger
      let e = action.payload.name;
      let arr_entities = state.entities;
      if(e != undefined &&!state.entities.includes(e)){
         arr_entities = state.entities.concat(e);
      }console.log(arr_entities)
      return assign({},state,{entities:arr_entities})

    case types.WIT_APP:
       return assign({},state,{app:action.data})

    case types.EN_WIT_APP:
    // debugger
         console.log(action.data);
         return assign({},state,{en_app:action.data})
    case types.SV_WIT_APP:
         console.log(action.data);
         return assign({},state,{sv_app:action.data})

         case types.NLP:
         return assign({},state,{nlp:action.nlp})

         case types.WIT_ENTITY_VALUES:console.log(action.data.values)
          return assign({},state,{entity_values:action.data.values})

          case types.GET_ALL_SAMPLES:
          return assign ({},state,{AllSamples:action.payload})

          case types.APP_NAME:
          return assign ({},state,{appName:action.data})
    default:
      return state
  } 
}
