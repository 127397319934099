import * as React from 'react';
import { Icon, Button, Card, Form, Input, Divider, Row, Col, Tooltip, Select,Dropdown,Menu,List } from 'antd';
import { connect } from 'react-redux';
import { changeLanguage, deleteDialogue, setMode, addDialogue, setSelectedDialogue, setSelectedArc, deleteArc } from '../../../actions';
import { Mode } from '../../../constants/types';
import { API } from '../../../api/api';
import Response from './response';
import Variable from './variable';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';
const Option = Select.Option;

interface IArcProps {
  deleteArc: () => void;
  setSelectedArc: (id?: number) => void;
  selectedArc?: any;
  language?: any;
  mode?: Mode;
  dialogues?:any;
  variables?:any;
  lo?:any;
  selectedDialogue?:any;
  currentProject?:any;
};

interface IArcState {
  selectedArc?: any;
  curId?: any;
  conditionsToRemove?: any;
  lo?:any;
  variables?:any;
};

class Arc extends React.Component<IArcProps, IArcState> {
  state: IArcState = {};

  componentDidUpdate(prevProps) {

    if (this.props.selectedArc && this.props.selectedArc !== prevProps.selectedArc) {
      this.fetchArc();
      this.getVariables();
    }
  }

  fetchArc = () => {
    API.getArc(this.props.selectedArc.id).then( (r: any) => {
      let selectedArc = {from: r.data.parent, to: r.data.child, id: this.props.selectedArc, conditions: r.data.conditions};
      // availableOptionsLength: 0, todo add this to the backend
      //   availableOptions: {0: {content_type: 'text', content: {en: '-- select an option --', sv: '-- select an option --'}}
      let curId = 0;
      selectedArc.conditions = selectedArc.conditions.map(e => {
        e.index = curId++;
        e.selectedOptiom = e.option ? e.option.id.toString() : '0';
        // e.selectedVariable = e.option.variable_id;
        return e;
      });
      let conditionsToRemove = {};
      // if (r.data.parent) {
      //   API.getAvailableOptions(r.data.parent.id).then( (c: any) => {
      //     c.data.forEach( o => selectedArc.availableOptions[o.id] = o.response);
      //     selectedArc.availableOptionsLength = c.data.length;
      //     this.props.setSelectedArc(selectedArc));
      //   });
      // } else {
      //   dispatch(setSelectedArc(selectedArc));
      // }
      this.setState({selectedArc, curId: selectedArc.conditions.length, conditionsToRemove});
    });
  }

  save = () => {
    let currentId = this.props.selectedArc.id;

    Object.keys(this.state.conditionsToRemove).forEach(conditionId => {
      let toRemove = this.state.conditionsToRemove[conditionId];
      if (toRemove.parameter) {
        API.deleteParameter(currentId, toRemove.parameter.id);
      }
      if (toRemove.option){
        API.deleteSequenceOption(toRemove.option.id, conditionId);
      }
    });

    this.state.selectedArc.conditions.forEach((condition, i) => {
      let parameter = condition.parameter;
      if (parameter) {
        parameter.variable_id = condition.variable_id;
        if (parameter.id) {
          API.editParameter(currentId, parameter);
        } else {
          if (parameter.value && !parameter.entity) {
            this.state.selectedArc.conditions[i].parameter.entity = 'intent';
            this.setState({selectedArc: this.state.selectedArc});
          }
          API.addParameter(currentId, parameter).then(r => {
            parameter.id = r.data.id;
            this.setState({selectedArc: this.state.selectedArc});
            return r;
          });
        }
      }
      let option = condition.selectedOption;
      debugger
      if (option && option !== 0 && (!condition.option || condition.option.id !== option)) {
        API.addSequenceOption(currentId, condition.selectedOption, condition.id);
      } if (option === 0 && condition.option) {
        API.deleteSequenceOption(condition.option.id, condition.id);
      }
      condition.option = option;
    });
    this.setState({selectedArc: this.state.selectedArc, conditionsToRemove: {}});
  }

  addConditionRow = () => {
    this.state.selectedArc.conditions.push({ index: this.state.curId });
    this.setState({selectedArc: this.state.selectedArc, curId: this.state.curId + 1});
  }

  removeCondition = (rowId) => {
    let index = -1;
    for (var i = 0; i < this.state.selectedArc.conditions.length; i++) {
      if (this.state.selectedArc.conditions[i].index === rowId) {
        index = i;
        if (this.state.selectedArc.conditions[i].id) {
          this.state.conditionsToRemove[this.state.selectedArc.conditions[i].id] = this.state.selectedArc.conditions[i];
        }
        break;
      }
    }
    this.state.selectedArc.conditions.splice(index, 1);
    this.setState({selectedArc: this.state.selectedArc, conditionsToRemove: this.state.conditionsToRemove});
  }

  removeConditionParameter = (condition, i) => {
    if (condition.parameter.id) {
      if (!this.state.conditionsToRemove[condition.id]) {
        this.state.conditionsToRemove[condition.id] = {};
      }
      this.state.conditionsToRemove[condition.id].parameter = condition.parameter;
    }
    this.state.selectedArc.conditions[i].parameter = undefined;
    this.setState({selectedArc: this.state.selectedArc, conditionsToRemove: this.state.conditionsToRemove});
  }

  addConditionParameter = (i) => {
    this.state.selectedArc.conditions[i].parameter = {condition_id: this.state.selectedArc.conditions[i].id};
    this.setState({selectedArc: this.state.selectedArc});
  }

  getVariables= () => {API.getVariables(this.props.selectedArc.id).then((r:any)=> {this.setState({variables:r.data})})}
  getVariable =(variableId) =>{API.getVariable(variableId).then((r:any)=> {console.log(r)})}
  menu1 = (condition, i) => (
   this.state.variables && <Menu>
    <Menu.ItemGroup title='Parent variable'>
  {this.state.variables.parent_variables && this.state.variables.parent_variables.map(variable =>
     <Menu.Item key={variable.id} onClick={() => {
       this.state.selectedArc.conditions[i].selectedVariable = variable;
       this.setState({selectedArc: this.state.selectedArc});} }>
       <a style={{backgroundColor: 'transparent', borderRadius: 4 , paddingRight:30}} >
         {variable.name}
       </a>
    </Menu.Item>
  )}
    </Menu.ItemGroup>
    <Menu.ItemGroup title='Context variable'>
     {this.state.variables.context_variables && this.state.variables.context_variables.map(variable =>
        <Menu.Item key={variable.id} onClick={() => {
          this.state.selectedArc.conditions[i].selectedVariable = variable;
          this.setState({selectedArc: this.state.selectedArc});} }>
          <a style={{backgroundColor: 'transparent', borderRadius: 4 , paddingRight:30}} >
            {variable.name}
          </a>
       </Menu.Item>
     )}
    </Menu.ItemGroup>

    <Menu.ItemGroup title='Rest variable'>
    {this.state.variables.rest_variables && this.state.variables.rest_variables.map(variable =>
       <Menu.Item key={variable.id} onClick={() => {
         this.state.selectedArc.conditions[i].selectedVariable = variable;
         this.setState({selectedArc: this.state.selectedArc});} }>
         <a style={{backgroundColor: 'transparent', borderRadius: 4 , paddingRight:30}} >
           {variable.name}
         </a>
      </Menu.Item>
    )}
    </Menu.ItemGroup>

   </Menu>
  )

  menu2 = (condition, i) => (
   <Menu>
     <Menu.ItemGroup title='Options'>
  {condition.selectedVariable.options && condition.selectedVariable.options.map(option =>
     <Menu.Item key={option.id} onClick={() => {
       this.state.selectedArc.conditions[i].selectedOption = option.id;
       this.setState({selectedArc: this.state.selectedArc});} }>
       <a style={{backgroundColor: 'transparent', borderRadius: 4 , paddingRight:30}} >
         {option.response[0]?option.response[0].name:option.id}

         {console.log(option)}
       </a>
    </Menu.Item>
  )}
  </Menu.ItemGroup>
</Menu>);
  // lo=this.props.dialogues.map(x=> x.varibles)
  render() {
    return this.props.mode !== Mode.chat && this.props.selectedArc && this.state.selectedArc ? (
      <Card className='details panel panel-info' title='Edge info'
      actions={[<Row type='flex' justify='end' className='card-footer'>
        <Button onClick={() => this.props.deleteArc()} type='danger'>Delete</Button>
        <Button onClick={this.save} type='primary'>Save</Button>
        <Button onClick={() => this.props.setSelectedArc(undefined)}>Cancel</Button>
      </Row>]}>
        <div > <b>From:</b> {this.state.selectedArc.from && this.state.selectedArc.from.name} </div>
        <div > <b>To:</b> {this.state.selectedArc.to.name} </div>

  {/* <!-- Conditions --> */}

  <div style={{marginTop:"15px"}}>

  </div>
        <div>
          <Divider>Conditions</Divider>

          {this.state.selectedArc.conditions.map((c, i) => (
          <div key={'condition' + i}>
          {this.state.selectedArc.from && <Dropdown overlay={this.menu1(c, i)} trigger={['click']}>
          <a className="ant-dropdown-link">
          {c.selectedVariable ? c.selectedVariable.name : "Variable"}
           <Icon type="down" />
          </a>
            </Dropdown> }

            { c.selectedVariable && <Dropdown overlay={this.menu2(c, i)} trigger={['click']}>
            <a className="ant-dropdown-link" style={{marginLeft:"29px"}}>
            {c.selectedOption ? c.selectedOption : "Option"} <Icon type="down" />
            </a>
              </Dropdown> }
            <Row type='flex' justify='space-around' align='middle'>
            <Col span={21}>


        Conditon {i}

            {/* <!-- Parameter --> */}
            { c.parameter ?
            <div style={{width: '100%'}}>
              { (c.parameter.value || (!c.parameter.max && !c.parameter.min)) &&
                <Input placeholder='Intent' value={c.parameter.value} onChange={(e) => {
                  this.state.selectedArc.conditions[i].parameter.value = e.target.value;
                  this.setState({selectedArc: this.state.selectedArc}); }} />
              }
              { c.parameter.entity !== 'intent' &&
                <Row>
                  <Col xs={12}>
                  <Tooltip trigger='hover' title='key' placement='top' overlayClassName='numeric-input'>
                    <Input placeholder='key' value={c.parameter.entity} onChange={(e) => {
                    this.state.selectedArc.conditions[i].parameter.entity = e.target.value;
                    this.setState({selectedArc: this.state.selectedArc}); }} />
                    </Tooltip>
                  </Col>
                  <Col xs={6}>
                  <Tooltip trigger='hover' title='Minimum value' placement='top' overlayClassName='numeric-input'>
                    <Input placeholder='min' value={c.parameter.min} onChange={(e) => {
                    this.state.selectedArc.conditions[i].parameter.min = e.target.value;
                    this.setState({selectedArc: this.state.selectedArc}); }} />
                    </Tooltip>
                  </Col>
                  <Col xs={6}>
                  <Tooltip trigger='hover' title='Maximum value' placement='top' overlayClassName='numeric-input'>
                    <Input placeholder='max' value={c.parameter.max} onChange={(e) => {
                    this.state.selectedArc.conditions[i].parameter.max = e.target.value;
                    this.setState({selectedArc: this.state.selectedArc}); }} />
                    </Tooltip>
                  </Col>
                </Row>
              }
              <Icon type='close' style={{display: 'block', cursor: 'pointer', color: 'red', paddingTop: 2}} title='Remove Parameter'
                onClick={() => this.removeConditionParameter(c, i)} />
            </div>
            : <div style={{marginTop: 5}}><a onClick={() => this.addConditionParameter(i)}>Add Parameter</a></div>
            }
            </Col>
            <Col span={3}>
            <Icon style={{ float: 'right' }}
              className='dynamic-delete-button'
              type='minus-circle-o'
              onClick={() => this.removeCondition(c.index)}
            /> </Col>
            </Row>
            <Divider/>
          </div>
        ))}
          <Row type='flex' justify='center'><Col span={20}>
            <Button type='dashed' onClick={this.addConditionRow} style={{ width: '100%', marginBottom: 15 }}>
              <Icon type='plus' /> Add condition
            </Button>
          </Col></Row>
        </div>
      </Card>
    ) : null;
  }
}

function mapStateToProps(state: any) {
  let {selectedArc, mode,variables,selectedDialogue} = state.bot;
  return {
    selectedArc, mode,selectedDialogue,
    language: state.language.value,
    dialogues: state.bot.dialogues,
    currentProject: state.bot.currentProject
  };
}

const mapDispatchToProps = {
  deleteArc,
  setSelectedArc
};

const WrappedRegistrationForm = Form.create()<any>(Arc);

export default connect<any>(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);
