import * as React from 'react';
import { connect } from 'react-redux';
import { Card, Button, Tag, Row, Input, Col, Form } from 'antd';
import { setMode, changeContextOnMessage } from '../../../actions';
import { Mode } from '../../../constants/types';
import { API } from '../../../api/api';

interface IchatProps {
  mode: Mode;
  language: any;
  changeContextOnMessage?: (context, dialogue) => void;
  value?: any;
  currentProject?: any;
};

interface IchatState {
  options: any[];
  messages: any[];
  chatText: string;
}

class Chat extends React.Component<IchatProps, IchatState> {
  state = {
    options: [],
    messages: [],
    chatText: ''
  }

  messagesEnd;
  scrollHeight = 400;

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollTop += this.scrollHeight;
    }
  }

  sendMessage = (message) => {
    this.setState({
      options: [],
      messages: [...this.state.messages, { sentBy: 'user', data: { text: message } }, { sentBy: 'bot', data: { text: '...' } }]
    });

    API.sendMessage(this.props.currentProject, message).then((r) => {
      let options = r.data.dialogue && r.data.dialogue.options ? r.data.dialogue.options : [];
      let responses = r.data.dialogue ? r.data.dialogue.responses : [];

      if (r.data.variable) {
        Array.prototype.push.apply(options, r.data.variable.options);
        Array.prototype.push.apply(responses, r.data.variable.responses);
      }
      if (r.data.user_chatbot_session) {
        this.props.changeContextOnMessage!(r.data.user_chatbot_session.context_id, r.data.user_chatbot_session.dialogue_id);
      }
      this.setState({
        options,
        messages: [...this.state.messages.slice(0, -1), ...responses.map(r => { return { sentBy: 'bot', data: r }; })]
      });
    });
  }

  submitForm = (e: any) => {
    e.preventDefault();
  }

  renderMesseges() {
    return <div className="msger-chat" style={{ border: 0, minHeight: this.scrollHeight, maxHeight: this.scrollHeight, overflowX: 'hidden', overflowY: 'auto', }}
      ref={(el) => { this.messagesEnd = el; }}>
      {this.state.messages.map((message:any, index) => {
        return <div className="" key={index} style={{ padding: 10 }}>
          {message.sentBy === 'bot' ?
            <div className="msg left-msg">
              <img alt=""
                className="msg-img"
                src="assets/images/optobot_icon.png"
              />
              <div className="msg-bubble">
                <div className="msg-text">
                  {message.data.text && <span style={{ wordBreak: 'break-word' }}>{message.data.text}</span>}
                  {message.data.video && <a href={message.data.video}>{message.data.video}</a>}
                  {message.data.image && <img alt="" src={message.data.image} style={{ maxWidth: '100%' }} />}
                </div>
              </div>
            </div>
            :
            <div className="msg right-msg">
              <img alt=""
                className="msg-img"
                src="assets/images/user_icon.png"
              />

              <div className="msg-bubble">
                <div className="msg-text">
                  {message.data.text && <span style={{ wordBreak: 'break-word' }}>{message.data.text}</span>}
                  {message.data.video && <a href={message.data.video}>{message.data.video}</a>}
                  {message.data.image && <img alt="" src={message.data.image} style={{ maxWidth: '100%' }} />}
                </div>
              </div>
            </div>
          }
        </div>

      })}
        {this.state.options.map((option:any, index) => option.text ?
           <Tag color='cornflowerblue' onClick={() => this.sendMessage(option.text)}>{option.text}</Tag>:
          <img alt="" key={index} src={option.image} style={{ maxWidth: '100%' }} onClick={() => this.sendMessage(option.image)} />)
        }
    </div>

  }

  render() {
    return this.props.mode === Mode.chat ? (
      <Card className='details panel panel-info' title='Chat'>
        {this.renderMesseges()}
        <form onClick={this.submitForm} className="msger-inputarea">
          <input type="text"
            className="msger-input"
            placeholder="Enter your message..."
            onChange={(e) => this.setState({ chatText: e.target.value })} value={this.state.chatText} />
          <button type="submit" className="msger-send-btn" onClick={() => {
            this.sendMessage(this.state.chatText);
            this.setState({ chatText: '' });
          }}>Send</button>
        </form>
      </Card>
    ) : null;
  }
}


function mapStateToProps(state: any) {
  return {
    language: state.language,
    mode: state.bot.mode,
    currentProject: state.bot.currentProject
  };
}

const mapDispatchToProps = {
  setMode, changeContextOnMessage
};

export default connect<IchatProps>(
  mapStateToProps,
  mapDispatchToProps
)(Chat);
