import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button,Select, List, Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import {addUser, removeUser, setUsers} from '../actions';
import { Link } from 'react-router-dom';
import { RcBaseFormProps } from 'antd/lib/form/Form';
import { API } from '../api/api';
const Option = Select.Option;


interface UsersListProps {
  form?:any;
  currentProject?: number;
  addUser: (ProjectId:any,values: any) => void;
  removeUser: (userID: any,projectId:any, user_emails:any) => void;
  setUsers:(users:any) => void;
  projects?:any;
  match?: any;
  users?:any;
  user?:any;
}

interface UsersListState {
};

class UsersList extends React.Component<UsersListProps, UsersListState>{
  state = {
    addedEmail:'',
    addedRole:''
  };
  componentDidMount() {
      API.getUsers(this.props.user.attributes.id,this.props.match.params.id).then( r => {
        this.props.setUsers(r.data);
      });
      API.getUserChat(this.props.match.params.id,this.props.user.attributes.email).then( r => {
        console.log(r.data)
      });
  }

  addUser=()=>{
    let data = {role: this.state.addedRole,user_emails: [this.state.addedEmail]}
    this.props.addUser(this.props.match.params.id, data);
    this.setState({addedEmail:'',addedRole:''})
  }
  removeUser=(user)=>{
    this.props.removeUser(this.props.user.attributes.id,this.props.match.params.id,user.email)
    API.getUsers(this.props.user.attributes.id,this.props.match.params.id).then( r => {
      this.props.setUsers(r.data);

    });
  }
  render(){
    console.log('this.props',this.props)
    let projectName = this.props.projects.find(p=> p.id === this.props.currentProject).name

    // const {getFieldDecorator} = this.props.form;
    return(
      <div className='users-list'>
        <h1 className='title'>{projectName}</h1>
        <div className='table'>
          <h1> Add users </h1>
          <div className='add'>
            <div className='inputs'>
              <Input placeholder='User Email' onChange={(e)=> this.setState({addedEmail: e.target.value})}/>
              <Select onChange={(e)=> this.setState({addedRole: e})}>
                <Option value='author'>Author</Option>
                <Option value='subscriber'>Subscriber</Option>
              </Select>                        
            </div>
            <Button onClick={this.addUser} className='bot-btn primary'  >
              Add
                {/* <Link style={{ color: '#FAEBD7' , align: "left",paddingRight:20 }} to={'/project/'+this.props.match.params.id+'/adduser'}>Add User</Link> */}
            </Button>
          </div>
          <div className='list'>
            {this.props.users.map(user=>{
              console.log(user)
              return <div className='row' key={user.id}>
                  <Link style={{ width: '40%'}}  to={`/project/${this.props.currentProject}/userChat`}>
                    <h1>{user.email}</h1>
                  </Link>                 
                  <h1>{user.role}</h1>
                  <span style={{cursor:'pointer'}} onClick={()=>this.removeUser(user)} >
                    <Icon style={{fontSize:22, color:'#492C6E'}} type="delete" />
                  </span>
              </div>
            })}
          </div>
        </div>
          
          {/* <List
          itemLayout='horizontal'
          dataSource={this.props.users}
          renderItem={user => (
            <List.Item style={{backgroundColor: 'transparent', borderRadius: 4 , paddingRight:30}}>
              <ol>
                    <li>
                    <Link  to={`/project/${this.props.currentProject}/userChat`}>
                      <h4 style={{color: '#1890ff', marginLeft: 10}} >User :{user.email}</h4>
                      </Link>
                    </li>
                    <li>
                        <h4 style={{color: '#1890ff', marginLeft: 10}} >role :</h4>
                    </li>
              </ol>
              <Button type='primary'  onClick={() => this.props.removeUser(this.props.user.attributes.id,this.props.match.params.id,user.email)} >
                Delete User
                 </Button>
          </List.Item>
          )}
        /> */}
         
      </div>
    )
}
}

function mapStateToProps(state: any) {
  return {
    user: state.reduxTokenAuth.currentUser,
    projects:state.projects,
    users:state.users,
    currentProject: state.bot.currentProject,

      };
}
const mapActionsToProps = {
addUser,removeUser ,setUsers
};


const WrappedNormalRegisterForm = Form.create()<any>(UsersList);

export default connect<any>(mapStateToProps,mapActionsToProps)(WrappedNormalRegisterForm);
