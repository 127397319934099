import * as React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Router, Route, Switch} from 'react-router';
import { Redirect} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import {includes} from 'lodash'
import Header from '../components/Header';
import { generateRequireSignInWrapper } from 'redux-token-auth';
import SignIn from '../components/SignIn';
import LoggedIn from '../components/LoggedIn';
import SignUp from '../components/SignUp';
import PageHeader from '../components/Header';
import projectForm from '../components/Project/CreateProject_Form';
import Account from '../components/Project/Account';
import { Layout } from 'antd';
import HomeHeader from "../components/HomeHeader";
import ProjectMenu from "../components/ProjectMenu";
import ProjectApp from "./ProjectApp";
import WitForm from '../components/witA' 
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import ResetPassword from '../components/ResetPassword';
import Dashboard from '../components/Project/Dashboard';
import Bot from '../components/Project/Bot';
import AddUser from '../components/AddUser';
import UsersList from '../components/UsersList';
import AccountHome from '../components/Project/AccountHome';
import UserChat from '../components/UserChat';
import UsersData from '../components/UsersData';
import witA from '../components/witA';
import ForgotPassword from '../components/ForgotPassword';
library.add(faCheck)
const { Content, Footer } = Layout;


const requireSignIn: (a:any) => any = generateRequireSignInWrapper({
  redirectPathIfNotSignedIn: '/signin',    // edited from '/signin'
});

const history = createBrowserHistory({});

class App extends React.Component<{isSignedIn: boolean,   currentProject?: number;location?:any}> {

  render() {
    console.log('history',history)
    return (
      <Router history={history}>
       <Layout id="proApp" className='layout'> 
    
      {this.props.isSignedIn && <PageHeader />  }
      <Content style={{height:'100%'}} >
        <div style={{height:'100%'}}>            
          <Route exact path='/signin' component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/resetPassword" component={ResetPassword} />
          <Route exact path="/forgotPassword" component={ForgotPassword} />
          <Content className='home-pages'  >
            <Route exact path='/' component={requireSignIn(Account)}  />   
            <Route exact path='/account' component={requireSignIn(Account)} />
            <Route exact path="/newProject" component={requireSignIn(projectForm)} />
            <Route exact path="/newApp" component={requireSignIn(WitForm)} />
          </Content>
         
          <Route path="/project/:id?/:state?" render={(props) => <ProjectApp {...props}  />}/>          
        </div>
      </Content>

      
    </Layout>
    </Router>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    isSignedIn: state.reduxTokenAuth.currentUser.isSignedIn,
    currentProject: state.bot.currentProject,
  };
}

export default connect(mapStateToProps)(App);
