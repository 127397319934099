import * as React from 'react';
import { Icon, Button, Card, Form, Input, Divider, Row, Col, Switch } from 'antd';
import { connect } from 'react-redux';
import { deleteDialogue, setMode, addDialogue, setSelectedDialogue, setSelectedArc } from '../../../actions';
import { Mode } from '../../../constants/types';
import { API } from '../../../api/api';
import Response from './response';
import Variable from './variable';
const FormItem = Form.Item;

interface IDialogueProps {
  deleteDialogue?: () => void;
  setMode?: (mode: Mode) => void;
  addDialogue?: (parentId?: number) => void;
  setSelectedDialogue?: (id?: number) => void;
  setSelectedArc?: (arc: any) => void;
  selectedDialogue?: number;
  selectedContext?: number;
  language?: any;
  mode?: Mode;
  form?: any;
  dialogues?:any;
  parents_id?:any;
};

interface IDialogueState {
  selectedDialogue?: any;
  curId?: any;
  toRemove?: any;
  responseModal? : boolean;
  currentResponse? : number;
  variableModal? : boolean;
  currentVariable? : number;
  terminalNode?: boolean;
  dialogues?:any;
  parents_id?:any;
  lo?:any;
  lp?:any;
  po?:any;
  responses?:any;
};

// dialogue info, When clicking on dialogue
class Dialogue extends React.Component<IDialogueProps, IDialogueState> {

  state: IDialogueState = {
    responseModal: false,
    variableModal: false,
    terminalNode: false,
    lo:[],
    lp:[],
    po:false
  };

  componentDidUpdate(prevProps) {
    if (this.props.selectedDialogue && this.props.selectedDialogue !== prevProps.selectedDialogue) {
      this.fetchDialogue();
    }
  }

  fetchDialogue = () => {
    API.getDialogue(this.props.selectedDialogue).then((r: any) => {
      let selectedDialogue = r.data;
      let curId = 0;
      curId = 0;
      selectedDialogue.variables = selectedDialogue.variables.map(e => {e.index = curId++; return e; });
      curId = 0;
      selectedDialogue.responses = selectedDialogue.responses.map(e => {e.index = curId++; return e; });
      let dialogueCurId = {
        variables: selectedDialogue.variables.length,
        responses: selectedDialogue.responses.length
      };
      let toRemove = {variables: [], responses: []};
      this.setState({selectedDialogue, curId: dialogueCurId, toRemove, responseModal: false, variableModal: false,
        terminalNode: this.props.selectedContext == null || selectedDialogue.responses.length > 0});
    });
  }

  remove = (key, rowId) => {
    let index = -1;
    for (let i = 0; i < this.state.selectedDialogue[key].length; i++) {
      if (this.state.selectedDialogue[key][i].index === rowId) {
        index = i;
        if (this.state.selectedDialogue[key][i].id) {
          this.state.toRemove[key].push(this.state.selectedDialogue[key][i]);
        }
        break;
      }
    }
    this.state.selectedDialogue[key].splice(index, 1);
    this.setState({selectedDialogue: this.state.selectedDialogue, toRemove: this.state.toRemove});
  }

  addResponse = () => {
    let selectedDialogue = this.state.selectedDialogue;
    let curId = this.state.curId;
    selectedDialogue.responses.push({ index: this.state.curId.responses });
    curId.responses++;
    this.setState({curId, selectedDialogue});
  }


  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        ///
        this.state.selectedDialogue.dialogue.name = values.name;
        this.state.selectedDialogue.dialogue.intent = values.intent;
        this.setState({selectedDialogue: this.state.selectedDialogue});
        this.save();
      }
    });
  }

  save = () => {
    // delete to be deleted
    Object.keys(this.state.toRemove).forEach(key => {
      this.state.toRemove[key].forEach(element => {
        API.delete(key, element, this.state.selectedDialogue.dialogue.id);
      });
    });

    API.editDialogue(this.state.selectedDialogue.dialogue);

    this.state.selectedDialogue.responses.forEach(response => {
      if (!response.id) {
        API.addResponse(this.state.selectedDialogue.dialogue.id).then( r => {
          API.addResponseContent(this.state.selectedDialogue.dialogue.id, r.data.id, {content_type: 'text', content: response.addedResponse});
          response.id = r.data.id;
          response.content_type = 'text';
          response.content = response.addedResponse;
          response.addedResponse = null;
          this.setState({selectedDialogue: this.state.selectedDialogue});
          return r;
        })
      }
    });

    this.setState({toRemove: {variables: [], responses: []}});
  }

  openResponseModal = (response?) => {
    this.setState({ responseModal: true, currentResponse: response });
  }
  cancelResponseModal = () => {
    this.setState({ responseModal: false });
  }
  openVariableModal = (variable?) => {
    this.setState({ variableModal: true, currentVariable: variable });
  }
  cancelVariableModal = () => {
    this.setState({ variableModal: false });
  }

  addDialogueCondition = () => {
    let selectedIndex = this.props.dialogues.findIndex(e => e.id === this.state.selectedDialogue.dialogue.id);
    let arcIds = Object.keys(this.props.dialogues[selectedIndex].arcsRaw);
    let parentIndex = arcIds.findIndex(e => this.props.dialogues[selectedIndex].arcsRaw[e].parent_id == null);
    if (parentIndex === -1) {
      API.addParentRoot(this.state.selectedDialogue.dialogue.id).then( (r: any) => {
        let arcIds = Object.keys(r.data.arcs);
        let parentIndex = arcIds.findIndex(e => r.data.arcs[e].parent_id == null);
        let arcId = arcIds[parentIndex];
        this.props.setSelectedArc!({id: arcId, child: this.state.selectedDialogue.dialogue.id, parent: null});
      });
    } else {
      let arcId = arcIds[parentIndex];
      this.props.setSelectedArc!({id: arcId, child: this.state.selectedDialogue.dialogue.id, parent: null});
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        }
      },
    };
    let lo=[];
    return this.props.mode !== Mode.chat && this.props.selectedDialogue && this.state.selectedDialogue ? (
      <Card className='details panel panel-info'
      title={
        <div>{this.state.selectedDialogue.dialogue.name}<Button style={{float: 'right'}} onClick={this.addDialogueCondition}>Add Conditions</Button></div>
        }
      actions={[<Row type='flex' justify='end' className='card-footer'>
      <Button onClick={() => this.props.deleteDialogue!()} type='danger'>Delete</Button>
      <Button onClick={() => this.props.setMode!(Mode.addParent)}>Add Parent</Button>
      <Button onClick={() => this.props.addDialogue!(this.props.selectedDialogue)} disabled={this.state.po}>Add Child Dialogue</Button>
      <Button onClick={this.handleSubmit} type='primary'>Save</Button>
      <Button onClick={() => this.props.setSelectedDialogue!(undefined)}>Cancel</Button>
      </Row>]}>
        <Form onSubmit={this.handleSubmit}>
          <FormItem {...formItemLayout} label='Name'>
            {getFieldDecorator('name', {
              initialValue: this.state.selectedDialogue.dialogue.name,
              rules: [{required: true, message: 'Please input dialogue name!'}],
            })(
              <Input/>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='Intent'>
            {getFieldDecorator('intent', {
              initialValue: this.state.selectedDialogue.dialogue.intent,
              // rules: [{required: true, message: 'Please input dialogue intent!'}],
            })(
              <Input/>
            )}
          </FormItem>
          {this.state.selectedDialogue.dialogue.tag && <div style={{marginLeft: '20px', marginBottom: '10px'}}>
            Tag: {this.state.selectedDialogue.dialogue.tag}
          </div>}
          {this.props.selectedContext && <div style={{textAlign: 'center'}}>
                    {console.log((this.state.lo.push(this.props.dialogues.map(x=> x.parents_id.map(q=> q.id))),
                       this.state.lp=[]))}
                    {this.state.lo.forEach(x=> this.state.lp.push(x.filter(r=> r==this.props.selectedDialogue)))}
                    {console.log(this.state.lp[0].length)}

          {this.state.lp[0].length==0 && <div><span style={{ marginRight: 10}}> End of conversation </span>
          <Switch checked={this.state.terminalNode} onChange={terminalNode => (this.setState({terminalNode}), this.state.po=!this.state.po)} /> </div> }
          </div>}
          {/* <!-- Responses --> */
          this.state.terminalNode && <div>
            <Divider>Bot says</Divider>

            {this.state.selectedDialogue.responses.map((response, index) => (
              <div key={'response' + index} style={{ paddingBottom: 12}}>
              {!response.content || response == undefined ? <Input style={{width: '50%'}} placeholder='Text'
                  value={response.addedResponse && response.addedResponse[this.props.language]}
                    onChange={(e) => {
                      if (!this.state.selectedDialogue.responses[index].addedResponse) this.state.selectedDialogue.responses[index].addedResponse = {};
                      this.state.selectedDialogue.responses[index].addedResponse[this.props.language] = e.target.value;
                      this.setState({selectedDialogue: this.state.selectedDialogue}); }} /> :

                <a onClick={() => this.openResponseModal()}> {response.content_type === 'text' ? (response.content[this.props.language] ?
                  response.content[this.props.language].substr(0, 50) : '') +
                (!response.content[this.props.language] || response.content[this.props.language].length > 50 ? ' ...' : '') :
                response.content_type} </a>}



                <Icon style={{ float: 'right', marginTop: -4 }}
                  className='dynamic-delete-button'
                  type='minus-circle-o'
                  onClick={() => this.remove('responses', response.index)}
                />
              </div>
            ))}
            <Row type='flex' justify='center'><Col span={20}>
              <Button type='dashed' onClick={() => this.addResponse()} style={{ width: '100%', marginBottom: 15 }}>
                <Icon type='plus' /> Add response
              </Button>
            </Col></Row>
          </div>
          }
          {/* <!-- Variables --> */
          !this.state.terminalNode && <div>
            <Divider>Variables</Divider>
          {this.state.selectedDialogue.variables.map((variable, index) => (
            <div key={'response' + index} style={{ paddingBottom: 12}}>
              <a onClick={() => this.openVariableModal(variable.id)}> {variable.name + '. ' + (variable.wit_entities ? variable.wit_entities.join(',') : '') + (variable.ans_key ? ': ' + variable.ans_key : '')} </a>
              <Icon style={{ float: 'right', marginTop: -4 }}
                className='dynamic-delete-button'
                type='minus-circle-o'
                onClick={() => this.remove('variables', variable.index)}
              />
            </div>
          ))}
          <Row type='flex' justify='center'><Col span={20}>
            <Button type='dashed' onClick={() => this.openVariableModal()} style={{ width: '100%', marginBottom: 15 }}>
              <Icon type='plus' /> Add variable
            </Button>
          </Col></Row>
          </div>
          }
        </Form>
        <Response
          visible={this.state.responseModal}
          response={this.state.currentResponse}
          onSave={this.fetchDialogue}
          onCancel={this.cancelResponseModal}
        />
        <Variable
          visible={this.state.variableModal}
          variable={this.state.currentVariable}
          onSave={this.fetchDialogue}
          onCancel={this.cancelVariableModal}
        />
      </Card>
    ) : null;
  }
}

function mapStateToProps(state: any) {
  let {selectedDialogue, mode, selectedContext,dialogues} = state.bot;
  return {
    selectedDialogue, mode, selectedContext,
    language: state.language.value,
    dialogues,
    parents_id:state.bot.parents_id,
  };
}

const mapDispatchToProps = {
  deleteDialogue,
  setMode,
  addDialogue,
  setSelectedDialogue,
  setSelectedArc,
};

const WrappedRegistrationForm = Form.create()<any>(Dialogue);

export default connect<any>(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);
