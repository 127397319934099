import * as React from 'react';
import { Button, Icon } from 'antd'

// Code based on https://github.com/keppelen/react-facebook-login, updated until 2017-01-05
declare const window: any;

export default class FacebookPage extends React.Component<any,any> {

    static defaultProps = {
        scope: 'pages_manage_metadata, pages_show_list, pages_messaging, pages_messaging_subscriptions',
        xfbml: false,
        cookie: false,
        reAuthenticate: false,
        size: 'metro',
        // fields: 'facebook_page_access_token, facebook_page_id',
        version: '2.8',
        language: 'en_US',
        disableMobileRedirect: false,
    }

    state = {
        isSdkLoaded: false,
        isProcessing: false,
    }
    componentDidMount() {
        // let FB = window.FB;
        if( window.FB ) {
            const { appId, xfbml, cookie, version, autoLoad } = this.props
            window.FB.init( {
                version: `v${version}`,
                appId,
                xfbml,
                cookie,
            } )
            this.setState( { isSdkLoaded: true } )
            if( autoLoad || window.location.search.includes( 'facebookdirect' ) ) {
                window.FB.getLoginStatus( this.checkLoginAfterRefresh )
            }
            return
        }
        this.setFbAsyncInit()
        this.loadSdkAsynchronously()
        let fbRoot = document.getElementById( 'fb-root' )
        if( !fbRoot ) {
            fbRoot = document.createElement( 'div' )
            fbRoot.id = 'fb-root'
            document.body.appendChild( fbRoot )
        }
    }

    sdkLoaded() {
        this.setState( { isSdkLoaded: true } )
    }

    setFbAsyncInit() {
        const { appId, xfbml, cookie, version, autoLoad } = this.props
        window.fbAsyncInit = () => {
            window.FB.init( {
                version: `v${version}`,
                appId,
                xfbml,
                cookie,
            } )
            this.setState( { isSdkLoaded: true } )
            if( autoLoad || window.location.search.includes( 'facebookdirect' ) ) {
                window.FB.getLoginStatus( this.checkLoginAfterRefresh )
            }
        }
    }

    loadSdkAsynchronously() {
        const { language } = this.props;
        ( ( d, s, id ) => {
            const element = d.getElementsByTagName( s )[ 0 ]
            const fjs:any = element
            let js:any = element
            if( d.getElementById( id ) ) {
                return
            }
            js = d.createElement( s )
            js.id = id
            js.src = `//connect.facebook.net/${language}/all.js`
            fjs.parentNode.insertBefore( js, fjs )
        } )( document, 'script', 'facebook-login-sdk' )
    }

    responseApi = ( authResponse ) => {
        window.FB.api( '/me', { fields: this.props.fields }, ( me ) => {
            Object.assign( me, authResponse )
            this.props.callback( me )
        } )
    }

    checkLoginState = ( response ) => {
        console.log( 'checkLoginState', response.status )
        this.setState( { isProcessing: false } )
        if( response.authResponse ) {
            this.responseApi( response.authResponse )
        } else {
            if( this.props.callback ) {
                this.props.callback( { status: response.status } )
            }
        }
    }

    checkLoginAfterRefresh = ( response ) => {
        if( response.status === 'unknown' ) {
            window.FB.login( loginResponse => this.checkLoginState( loginResponse ), true )
        } else {
            this.checkLoginState( response )
        }
    }

    onClickLogin = () => {
        if( !this.state.isSdkLoaded || this.state.isProcessing || this.props.isDisabled ) {
            return
        }
        this.setState( { isProcessing: true } )
        const { scope, appId, reAuthenticate, disableMobileRedirect } = this.props
        console.log("scope!!!!!", scope)

        let isMobile = false

        try {
            isMobile = ( ( window.navigator && window.navigator.standalone ) ||
                navigator.userAgent.match( 'CriOS' ) || navigator.userAgent.match( /mobile/i ) )
        } catch( ex ) {
            // continue regardless of error
        }

        const params:any = {
            client_id: appId,
            redirect_uri: window.location.href,
            state: 'facebookdirect',
            scope
        }

        if( reAuthenticate ) {
            params.auth_type = 'reauthenticate'
        }

        if( isMobile && !disableMobileRedirect ) {
            window.location.href = `//www.facebook.com/dialog/oauth?${_objectToParams( params )}`
        } else {
            window.FB.login( this.checkLoginState, { scope, auth_type: params.auth_type } )
        }
    }

    render() {
        return (
            <div className='fb-login'>
                {/* <style jsx>
                {`
                    :global(.fb-btn, .fb-btn:hover){
                        background-color: rgb(60, 90, 153);
                        border-color: #3C5A99;
                        color: #fff;
                        text-align:left
                    }
                `}
                </style> */}
                <Button
                    onClick={this.onClickLogin}
                    size="large" 
                    loading={this.state.isProcessing}
                    className='bot-btn primary'
                    style={{ width: 'auto'}}
                >
                    <Icon type="facebook" /> Connect to Facebook Page
                </Button>
            </div> )
    }
}


function _objectToParams( paramsObj ) {
    let str = ''
    for( const key in paramsObj ) {
        if( str !== '' ) {
            str += '&'
        }
        str += `${key}=${encodeURIComponent( paramsObj[ key ] )}`
    }
    return str
}
