import * as React from 'react';
import { connect } from 'react-redux';
import { Card, Button } from 'antd';
import { setMode } from '../../../actions';
import { Mode } from '../../../constants/types';

interface IaddParentProps {
  setMode?: (mode: Mode) => void;
  mode: Mode;
};

class AddParent extends React.Component<IaddParentProps> {

  render() {
    return this.props.mode === Mode.addParent ? (
      <Card id='select-parent' style={{left:'150px'}}>
        Please select a parent <Button type='danger' style={{float: 'right'}} onClick={() => this.props.setMode!(Mode.edit)}>Cancel</Button>
      </Card>
    )  : null;
  }
}


function mapStateToProps(state: any) {
  return {
    mode: state.bot.mode
  };
}

const mapDispatchToProps = {
  setMode
};

export default connect<IaddParentProps>(
  mapStateToProps,
  mapDispatchToProps
)(AddParent);
