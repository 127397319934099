import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button,Select } from 'antd';
import { withRouter } from 'react-router-dom';
import {addUser, setUsers} from '../actions';
import { RcBaseFormProps } from 'antd/lib/form/Form';
import { API } from '../api/api';
const FormItem = Form.Item;
const Option = Select.Option;


interface UsersListProps {
form:any;
addUser: (ProjectId:any,values: any) => void;
match?: any;
users?:any;
history:any;
user?:any;
setUsers:(users:any) => void;

}

class AddUser extends React.Component<UsersListProps>{
  state ={
    users:{},
  }

  // componentDidMount() {
  //     API.getUsers(this.props.user.attributes.id,this.props.match.params.id).then(r => {
  //       this.setState({users: r.data});
  //     })
  // }

  submitForm = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let data = {role: values.role,
        user_emails: [values.email]}
         this.props.addUser(this.props.match.params.id,data);
      }
    });
}
  render(){

    const {getFieldDecorator} = this.props.form;

    return(
    <div>
      <Form  layout='vertical' onSubmit={this.submitForm} >
        <FormItem label='E-mail' labelCol={{ span: 5 }}
        wrapperCol={{ span: 12 }}>
          {getFieldDecorator('email', {
            required: true , rules: [{ message: 'Please input the user email !' }],
          })(
            <Input prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} type='email' placeholder='email' />
          )}
        </FormItem>
        <FormItem
          label="Role"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 12 }}
        >
          {getFieldDecorator('role', {
            rules: [{ message: 'Please select the user role !' }],
          })(
            <Select placeholder="Select a the role of the user">
              <Option value="auther">auther</Option>
              <Option value="subscriber">subscriber</Option>
            </Select>
          )}
        </FormItem>
        <FormItem>
      <Button type='primary'  htmlType='submit' >
        Add user
      </Button>
    </FormItem>
    </Form>
    </div>
    )
  }
}


function mapStateToProps(state: any) {
  return {
    user: state.reduxTokenAuth.currentUser,
    projects:state.projects,
    users:state.users,
      };
}
const mapDispatchToProps = { addUser,setUsers };


const WrappedNormalRegisterForm = Form.create()(AddUser);

export default connect<any>(mapStateToProps,mapDispatchToProps)(WrappedNormalRegisterForm);
