import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button,Select, List } from 'antd';
import { withRouter } from 'react-router-dom';
import {addUser, removeUser, setUsers} from '../actions';
import { Link } from 'react-router-dom';
import { RcBaseFormProps } from 'antd/lib/form/Form';
import { API } from '../api/api';
const Option = Select.Option;



interface UsersDataProps {
form?:any;
currentProject?: number;
addUser?: (ProjectId:any,values: any) => void;
removeUser?: (userID: any,projectId:any, user_emails:any) => void;
setUsers?:(users:any) => void;
projects?:any;
match?: any;
users?:any;
user?:any;
}

interface UserDataState{
  userData?:any;
}

class UsersData extends React.Component<UsersDataProps,UserDataState>{
  componentDidMount() {
    API.getUsersData(this.props.currentProject,this.props.user.attributes.id).then( r => {
      this.setState({userData:r.data})
    })
  }
  render(){


    return(
      <div>
      <h1> User Data  </h1>
      {/*this.state.userData && <h1 style={{marginLeft: '20px', marginBottom: '10px'}}>
        Name: {this.state.userData.name}
      </h1>*/}


      </div>
    )
}
}

function mapStateToProps(state: any) {
  return {
    user: state.reduxTokenAuth.currentUser,
    projects:state.projects,
    users:state.users,
    currentProject: state.bot.currentProject,

      };
}
const mapActionsToProps = {
addUser,removeUser ,setUsers
};

export default connect<any>(mapStateToProps,mapActionsToProps)(UsersData);
