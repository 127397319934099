export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_CONTEXT = 'CHANGE_CONTEXT';
export const SET_DIALOGUES = 'SET_DIALOGUES';
export const CHANGE_MODE = 'CHANGE_MODE';
export const SET_SELECTED_DIALOGUE = 'SET_SELECTED_DIALOGUE';
export const SET_SELECTED_ARC = 'SET_SELECTED_ARC';
export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';
export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_PROJECT_ID = 'SET_PROJECT_ID';
export const SET_USERS="SET_USERS";

export const SET_PARENTS="SET_PARENTS";

// export const SET_DIALOGUES = 'SET_DIALOGUES';
// export const SET_DIALOGUES = 'SET_DIALOGUES';

export const WIT_APP ="WIT_APP";
export const WIT_MESSAGE = "WIT_MESSAGE";
export const WIT_ENTITIES = "WIT_ENTITIES";
export const WIT_POST_ENTITIES = "WIT_POST_ENTITIES"
export const EN_WIT_APP = "EN_WIT_APP"
export const SV_WIT_APP = "SV_WIT_APP"
export const NLP ="NLP"
export const WIT_ENTITY_VALUES ='WIT_ENTITY_VALUES'
export const GET_ALL_SAMPLES = 'GET_ALL_SAMPLES'
export const APP_NAME = 'APP_NAME'